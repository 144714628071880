import * as React from 'react'
import { BlockHistory } from '../../interfaces/Interfaces'
import { TweenMax, Power2 } from 'gsap'
import { stringToBlockTitleObject } from 'common/CmsUtils'
import { IsomorphicURLSearchParams } from 'common/IsomorphicURL'
import ModAnimation from '../common/ModAnimation'
import ModBlockTitle from './ModBlockTitle'

export interface ModBlockHistoryState {
  sliderValue: number
  sliderActive: boolean
}

export interface ModBlockHistoryProps {
  content: BlockHistory
}

export default class ModBlockHistory extends React.Component<ModBlockHistoryProps, ModBlockHistoryState, {}> {
  draggable
  slider
  sliderContainer

  constructor(props) {
    super(props)

    this.state = {
      sliderValue: 0,
      sliderActive: false,
    }

    this.slider = React.createRef()
    this.sliderContainer = React.createRef()
    this.createHistoryTimeline = this.createHistoryTimeline.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.createHistoryTimeline)
    this.createHistoryTimeline()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.createHistoryTimeline)
  }

  createHistoryTimeline() {
    const { Draggable } = require('gsap/all')
    const { ThrowPropsPlugin } = require('../gsap-bonus/ThrowPropsPlugin')
    const myThis = this

    if (this.slider.offsetWidth <= this.sliderContainer.offsetWidth) {
      this.setState({ sliderActive: true })
      this.draggable = Draggable.create(this.sliderContainer, {
        type: 'x',
        bounds: { left: 0, width: this.slider.offsetWidth },
        throwProps: true,
        lockAxis: true,
        onDrag: function () {
          if (this.x == 0) {
            myThis.setState({ sliderValue: 0 })
          } else {
            myThis.setState({ sliderValue: this.x / this.minX })
          }
        },
        onThrowUpdate: function () {
          if (this.x == 0) {
            myThis.setState({ sliderValue: 0 })
          } else {
            myThis.setState({ sliderValue: this.x / this.minX })
          }
        },
      })
    } else {
      if (Draggable.get('.block-history__slider__container')) {
        Draggable.get('.block-history__slider__container').kill()
      }
      this.setState({ sliderActive: false })
    }
  }

  render() {
    const { content } = this.props

    const getBlockTitle = () => {
      if (content._i18nCL && content._i18nCL.title) {
        return <ModBlockTitle content={stringToBlockTitleObject(content._i18nCL.title)}></ModBlockTitle>
      } else {
        return null
      }
    }

    const history = content.items
      .slice()
      .reverse()
      .map((item, index) => {
        if (item._i18nCL && item._i18nCL.title) {
          return (
            <div className="block-history__element" key={index}>
              <h3>{item._i18nCL.title}</h3>
              <p className={'label'}>{item._i18nCL.text}</p>
            </div>
          )
        } else {
          return null
        }
      })

    const sliderChange = (el) => {
      this.setState({ sliderValue: el.target.value })
      TweenMax.to(this.sliderContainer, 0.5, {
        x: -(this.sliderContainer.offsetWidth - this.slider.offsetWidth) * el.target.value,
        ease: Power2.easeOut,
      })
    }

    return (
      <div className="block-history">
        {getBlockTitle()}
        <ModAnimation className="grid">
          <div className="cell small-24">
            <div className="block-history__slider" ref={(slider) => (this.slider = slider)}>
              <div
                className="block-history__slider__container"
                ref={(sliderContainer) => (this.sliderContainer = sliderContainer)}
              >
                {history}
              </div>
            </div>
          </div>
        </ModAnimation>
        <ModAnimation className="grid align-center">
          <div className="cell small-24 text-center">
            <input
              className={'block-history__range ' + (this.state.sliderActive ? '' : 'disabled')}
              type="range"
              min="0"
              max="1"
              value={this.state.sliderValue}
              step="0.01"
              onChange={sliderChange}
            />
          </div>
        </ModAnimation>
      </div>
    )
  }
}
