import * as React from 'react'
import { BlockLink } from "../../interfaces/Interfaces";
import ModAnchorTag from "../common/ModAnchorTag";
import ModAnimation from "../common/ModAnimation";

export interface ModBlockLinkProps {
  content: BlockLink
}

export default class ModBlockLink extends React.Component<ModBlockLinkProps, {}> {

  render() {
    const { content } = this.props

    if (!(content._i18nCL && content._i18nCL.title)) {
      return null
    }
    const i18n = content._i18nCL

    return (
      <div className="block-link grid align-center">
        <ModAnimation className="cell small-24 large-20 xlarge-18">
          <ModAnchorTag linkObject={content.link} linkText={true} hasIcon={true}>
            {i18n.title}
          </ModAnchorTag>
        </ModAnimation>
      </div>
    )
  }
}