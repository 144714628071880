export const filterQueryParams = {
  type: 'type',
  lang: 'lang',
  limit: 'limit',
  offset: 'offset',
  term: 'term',
  author: 'author',
  sector: 'sector',
  practice: 'practice',
  category: 'category',
  position: 'position',
  office: 'office',
}