import * as React from 'react'
import { createReactComponents } from 'common/DraftJsToReact'
import { RichText } from '../../interfaces/Interfaces'
import { richTextHasContent } from 'common/Utils'

export interface ModRichTextFragmentProps {
  richText: RichText
}

export default class ModRichTextFragment extends React.Component<ModRichTextFragmentProps, {}> {
  constructor(props) {
    super(props)
  }

  render() {
    const { richText } = this.props
    if (!richTextHasContent(richText)) return null

    return createReactComponents(richText, '', true)
  }
}
