import * as React from 'react'
import { BlockInNumbers } from '../../interfaces/Interfaces'
import { TweenMax, Power2, roundProps } from 'gsap'
import { injectIntl } from 'react-intl'
import { useInView, InView } from 'react-intersection-observer'
import { stringToBlockTitleObject } from 'common/CmsUtils'
import ModBlockTitle from './ModBlockTitle'

export interface ModBlockInNumbersProps {
  content: BlockInNumbers
}

const ModBlockInNumbers = (props: ModBlockInNumbersProps) => {
  const { content } = props
  const numbers = []

  const getBlockTitle = () => {
    if (content._i18nCL && content._i18nCL.title) {
      return <ModBlockTitle content={stringToBlockTitleObject(content._i18nCL.title)}></ModBlockTitle>
    } else {
      return null
    }
  }

  const countUp = (inView, entry) => {
    if (inView && entry.target.innerText == '0') {
      let counter = { var: 0 }
      TweenMax.to(counter, 1.5, {
        var: entry.target.dataset.number,
        onUpdate: function () {
          entry.target.innerText = Math.ceil(counter.var)
        },
      })
    }
  }

  const getNumbers = content.numbers.map((item, index) => {
    if (item._i18nCL.text && item._i18nCL.number) {
      return (
        <div className="cell small-12 medium-12 large-8 xlarge-6 block-in-numbers__element" key={index}>
          <InView
            className="block-in-numbers__number"
            onChange={(inView, entry) => countUp(inView, entry)}
            data-number={item._i18nCL.number}
            ref={(number) => {
              numbers.push(number)
            }}
          >
            0
          </InView>
          <span className="block-in-numbers__label label">{item._i18nCL.text}</span>
        </div>
      )
    } else {
      return null
    }
  })

  return (
    <div className="block-in-numbers">
      {getBlockTitle()}
      <div className="grid align-center">
        <div className="cell small-24 large-20 xlarge-18">
          <div className="grid">{getNumbers}</div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(ModBlockInNumbers)
