import * as React from 'react'
import CmsRouter from '../control/CmsRouter'
import { ClientCookieProvider } from './CookieProviderClient'

export default (
  <ClientCookieProvider>
    <CmsRouter initialLocation={window.location.href} />
  </ClientCookieProvider>
)

if (typeof window !== 'undefined' && 'scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}