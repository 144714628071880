import * as React from 'react'
import { BlockTitle } from '../../interfaces/Interfaces'
import ModAnimation from '../common/ModAnimation'
import { richTextHasContent } from 'common/Utils'
import ModRichTextFragment from 'components/common/ModRichTextFragment'

export interface ModBlockTitleProps {
  content: BlockTitle
}

export default class ModBlockTitle extends React.Component<ModBlockTitleProps, {}> {
  render() {
    const { content } = this.props

    if (!(content._i18nCL && richTextHasContent(content._i18nCL.title))) {
      return null
    }

    return (
      <div className="block-title grid align-center">
        <div className="cell small-24 medium-20 large-18 xlarge-16">
          <ModAnimation>
            <h2 className="block-title__title">
              <ModRichTextFragment richText={content._i18nCL.title} />
            </h2>
          </ModAnimation>
        </div>
      </div>
    )
  }
}
