import * as React from 'react'
import { Media } from 'interfaces/Interfaces'
import ModImgTag from './ModImgTag'

export interface ModLawyersTeaserImageProps {
  image?: Media
}

export default class ModLawyersTeaserImage extends React.Component<ModLawyersTeaserImageProps, {}> {
  render() {
    const { image } = this.props
    return image ? (
      <ModImgTag className="lawyer-teaser__image" imgObject={image} height={960} width={960} />
    ) : (
      <figure className="lawyer-teaser__image">
        <img src={require('static/img/_blank_square.gif')} alt="default" />
      </figure>
    )
  }
}
