import * as React from 'react'
import ModImgTag from '../common/ModImgTag'
import ModAnimation from '../common/ModAnimation'
import { BlockImage } from '../../interfaces/Interfaces'

export interface ModBlockImageProps {
  content: BlockImage
}

export default class ModBlockImage extends React.Component<ModBlockImageProps, {}> {
  render() {
    const { content } = this.props

    return (
      <div className="block-image grid align-center">
        <ModAnimation className="cell small-24 large-20 xlarge-18">
          <ModImgTag className={`block-image__image size__${content.imageSize}`} imgObject={content.image} />
          {content.caption?._i18nCL?.caption && <p className="block-image__caption">{content.caption._i18nCL.caption}</p>}
        </ModAnimation>
      </div>
    )
  }
}
