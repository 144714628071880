import * as React from 'react'
import { BlockAwards } from '../../interfaces/Interfaces'
import ModAnimation from '../common/ModAnimation'
import { stringToBlockTitleObject } from 'common/CmsUtils'
import ModBlockTitle from '../blocks/ModBlockTitle'

export interface ModBlockAwardsProps {
  content: BlockAwards
}

export default class ModBlockAwards extends React.Component<ModBlockAwardsProps, {}> {
  render() {
    const { content } = this.props

    const getBlockTitle = () => {
      if (content._i18nCL && content._i18nCL.title) {
        return <ModBlockTitle content={stringToBlockTitleObject(content._i18nCL.title)}></ModBlockTitle>
      } else {
        return null
      }
    }

    const awards = content.items.map((item, index) => {
      if (item._i18nCL && item._i18nCL.award) {
        return (
          <ModAnimation className="cell small-24 medium-12 large-8 xlarge-6 block-awards__element" key={index}>
            <h3 className="block-awards__title">{item._i18nCL.award}</h3>
            <span className="label">{item._i18nCL.years}</span>
          </ModAnimation>
        )
      } else {
        return null
      }
    })

    return (
      <div className="block-awards">
        {getBlockTitle()}
        <div className=" grid align-center">
          <div className="cell small-24 large-20 xlarge-18">
            <div className="grid">{awards}</div>
          </div>
        </div>
      </div>
    )
  }
}
