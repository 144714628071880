import ModAnchorTag from 'components/common/ModAnchorTag'
import ModAnimation from 'components/common/ModAnimation'
import ModRichText from 'components/common/ModRichText'
import { BlockBoxGrid } from 'interfaces/Interfaces'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

export interface ModBlockBoxGridProps {
  content: BlockBoxGrid
}

export default class ModBlockBoxGrid extends React.Component<ModBlockBoxGridProps> {
  constructor(props) {
    super(props)
  }

  render() {
    const { content } = this.props

    const items = content.items.map((item, index) => {
      if (!item._i18nCL?.title && item.buttons?.length == 0 && !item._i18nCL?.text) return null
      return (
        <ModAnimation key={index} className={`cell small-24 medium-12 large-${content.gridSize === '4' ? '6' : '8'}`}>
          <article className="block-box-grid__item">
            <div>
              {item._i18nCL?.title && <h3>{item._i18nCL.title}</h3>}
              {item._i18nCL?.text && <ModRichText richText={item._i18nCL.text} />}
            </div>
            {item.buttons?.length != 0 && (
              <div className="block-box-grid__item-buttons">
                {item.buttons.map((button, index) => {
                  if (button?.link) {
                    return (
                      <React.Fragment key={index}>
                        <ModAnchorTag linkObject={button.link} linkText={true} className="link link--internal">
                          {button?._i18nCL?.title ? (
                            button._i18nCL.title
                          ) : (
                            <FormattedMessage id="global.read-more" defaultMessage="Read more" />
                          )}
                        </ModAnchorTag>
                        <br />
                      </React.Fragment>
                    )
                  }
                  return null
                })}
              </div>
            )}
          </article>
        </ModAnimation>
      )
    })
    return <div className="block-box-grid grid">{items}</div>
  }
}
