import moment = require('moment')
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import ModAnchorTag from 'components/common/ModAnchorTag'
import ModSvg from 'components/common/ModSvg'
import ModImgTag from 'components/common/ModImgTag'
import { NavigationNode, Menu, NavigationHero, PageType, StaticPage, Page, Link } from '../interfaces/Interfaces'
import { isArticleActiveNow, createNodeLinkObject, findNavigationNodeById, createStaticPageLinkObject } from '../common/CmsUtils'
import { TweenMax, TimelineMax, Power1, Power2 } from 'gsap'
import RootState, { LoadingState } from '../vo/RootState'
import { offlineImage } from '../common/OfflineMedia'
import { supportedLanguages } from '../common/Languages'
import { Swiper, SwiperSlide } from 'swiper/react'

export interface ModNavigationState {
  menuOpen: boolean
  desktopWidth: number
  activeSubMenu: number
}

export interface ModNavigationProps {
  rootNavigationNode: NavigationNode
  contactNavigationNode: NavigationNode
  searchNavigationNode: NavigationNode
  mainMenu: Menu
  subMenu: Menu
  hero: NavigationHero
  rootState: RootState
  currentLanguage: string
  pathname: string
  query?: URLSearchParams
}

export default class ModNavigation extends React.Component<ModNavigationProps, ModNavigationState> {
  navTimeline = null
  ref = null

  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
      desktopWidth: 0,
      activeSubMenu: -1,
    }

    this.createNavigationNode = this.createNavigationNode.bind(this)
    this.createMainNavigationNode = this.createMainNavigationNode.bind(this)
    this.openMenu = this.openMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.createTimeline = this.createTimeline.bind(this)
    this.navTimeline = new TimelineMax({ paused: true })
    this.ref = React.createRef()
  }

  createTimeline() {
    if (this.state.desktopWidth !== window.innerWidth) {
      this.setState({ menuOpen: false })
      TweenMax.set('#header__nav', { clearProps: 'all' })
      TweenMax.set('#header__nav__layer', { clearProps: 'all' })
      TweenMax.set('#header__nav__teaser', { clearProps: 'all' })
      TweenMax.set('#header__nav .menu .blubb > span, #header__nav .menu--lang, #header__nav .header__nav__social__icon', {
        clearProps: 'all',
      })
      this.navTimeline.clear()

      this.navTimeline
        .add(TweenMax.to('#header__nav', 0.4, { left: 0, ease: Power2.easeOut }))
        .add(
          TweenMax.from('#header__nav__layer', 0.4, {
            scaleX: 0,
            transformOrigin: 'center left',
            ease: Power2.easeOut,
          })
        )
        .add(
          TweenMax.to('#header__nav__teaser', 0, {
            opacity: 1,
            ease: Power2.easeOut,
          })
        )
        .add(
          TweenMax.to('#header__nav__layer', 0.4, {
            scaleX: 0,
            transformOrigin: 'center right',
            ease: Power2.easeOut,
          })
        )
        .add(
          TweenMax.staggerFrom(
            '#header__nav .menu .blubb > span, #header__nav .menu--lang, #header__nav .header__nav__social__icon',
            0.4,
            { y: 100, ease: Power1.easeOut },
            0.1
          ),
          '-=1'
        )

      this.navTimeline.progress(0).pause()
      // this.navTimeline.reversed(true);
      this.navTimeline.eventCallback('onComplete', () => {
        this.navTimeline.pause()
      })

      this.setState({ desktopWidth: window.innerWidth })
    }
  }

  handleScroll() {
    if (document.documentElement.scrollTop > 250) {
      document.getElementById('header').classList.add('header--scrolled')
    } else {
      document.getElementById('header').classList.remove('header--scrolled')
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.createTimeline)
    window.addEventListener('scroll', this.handleScroll)
    this.setState({ desktopWidth: window.innerWidth })
    this.createTimeline()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.menuOpen) {
      this.navTimeline.play()
    } else {
      this.navTimeline.reverse()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.createTimeline)
  }

  openMenu(event) {
    if (document.getElementById('header').classList.contains('header--open')) {
      document.getElementById('header').classList.remove('header--open')
    } else {
      document.getElementById('header').classList.add('header--open')
    }
    this.setState({ menuOpen: !this.state.menuOpen, activeSubMenu: -1 }) // -1: close submenu
  }

  closeMenu(event) {
    document.getElementById('header').classList.remove('header--open')
    this.setState({ menuOpen: false, activeSubMenu: -1 }) // -1: close submenu
    this.navTimeline.reverse()

    if (window.location.pathname === '/en' || window.location.pathname === '/de' || window.location.pathname === '/fr') {
      window.scrollTo(0, 0)
    }
  }

  onMainMenuItemClicked(key: number) {
    // close if open
    if (this.state.activeSubMenu == key) {
      this.setState({ activeSubMenu: -1 }) // -1: close submenu
    } else {
      // open otherwise
      this.setState({ activeSubMenu: key })
    }
  }

  createMainNavigationNode(menu: Menu, pathname: string) {
    let isActive = false
    const navigation = menu.menuItems.reduce((prevItem, item, index) => {
      if (item.link?.internalLink?.node) {
        if (pathname === (item.link.internalLink.node as NavigationNode)._i18nCL?.relativeUrl) {
          isActive = true
        }
      }

      if (item.link?.url) {
        if (index == this.state.activeSubMenu) {
          isActive = true
        }
      }

      if (!item._i18nCL) {
        return prevItem
      }

      prevItem.push(
        <li
          key={index}
          onClick={item.link.internalLink ? this.closeMenu : () => this.onMainMenuItemClicked(index)}
          className={index == this.state.activeSubMenu ? 'is-active' : ''}
        >
          <ModAnchorTag linkObject={item.link}>
            <span className="blubb">
              <span>
                {item._i18nCL.label}

                {item.link?.url && item.subMenu && (
                  <React.Fragment>
                    {index == this.state.activeSubMenu ? <ModSvg icon="arrow-up" /> : <ModSvg icon="arrow-down" />}
                  </React.Fragment>
                )}
              </span>
            </span>
          </ModAnchorTag>
        </li>
      )

      return prevItem
    }, [])
    return { navigation, isActive }
  }

  createNavigationNode(menu: Menu, pathname: string) {
    let isActive = false
    const navigation = menu.menuItems.reduce((prevItem, item, index) => {
      if (item.link?.internalLink?.node) {
        if (pathname === (item.link.internalLink.node as NavigationNode)._i18nCL?.relativeUrl) {
          isActive = true
        }
      }

      if (!item._i18nCL) {
        return prevItem
      }

      prevItem.push(
        <li key={index} onClick={this.closeMenu}>
          <ModAnchorTag linkObject={item.link}>{item._i18nCL.label}</ModAnchorTag>
        </li>
      )

      return prevItem
    }, [])
    return { navigation, isActive }
  }

  createLanguageNodes(
    rootState: RootState,
    rootNode: NavigationNode,
    currentLanguage: string,
    pathname: string,
    query?: URLSearchParams
  ) {
    let languageObjects = {}

    if (rootState.currentPage) {
      let currentPage
      let pageType
      try {
        currentPage = rootState.pages[rootState.currentPage.type][rootState.currentPage.id]
        pageType = rootState.currentPage.type
      } catch (error) {
        //default
      }

      if (pageType == PageType.page) {
        const page = currentPage as Page

        let navigationNode
        if (typeof page.navigationNode === 'string') {
          navigationNode = findNavigationNodeById(page.navigationNode, rootNode)
        } else {
          navigationNode = page.navigationNode
        }
        supportedLanguages.forEach((lang) => {
          if (navigationNode.i18n.hasOwnProperty(lang) && isArticleActiveNow(page, lang)) {
            languageObjects[lang] = createNodeLinkObject(navigationNode, null, lang)
          }
        })
      } else {
        let staticPage = currentPage as StaticPage
        supportedLanguages.forEach((lang) => {
          if (staticPage.i18n && staticPage.i18n.hasOwnProperty(lang) && staticPage.i18n[lang].relativeUrl) {
            languageObjects[lang] = createStaticPageLinkObject(staticPage, pageType, lang)
          }
        })
      }
    }

    supportedLanguages.forEach((lang) => {
      if (!languageObjects.hasOwnProperty(lang) && rootNode.i18n.hasOwnProperty(lang)) {
        languageObjects[lang] = createNodeLinkObject(rootNode, null, lang)
      }
    })

    let languageNodes = Object.keys(languageObjects).map((key) => {
      if (key === 'it') return null
      const linkObject: Link = languageObjects[key]

      if (query && linkObject.internalLink) {
        const queryParams = []
        query.forEach((value, key) => {
          queryParams.push({ key: key, value: value })
        })

        linkObject.internalLink.queryParams = queryParams
      }
      return (
        <li key={key} onClick={this.closeMenu}>
          <ModAnchorTag linkObject={languageObjects[key]}>{key}</ModAnchorTag>
        </li>
      )
    })

    return <ul className="menu menu--lang">{languageNodes}</ul>
  }

  render() {
    const { menuOpen } = this.state
    const {
      rootNavigationNode,
      contactNavigationNode,
      searchNavigationNode,
      mainMenu,
      subMenu,
      hero,
      currentLanguage,
      pathname,
      query,
      rootState,
    } = this.props

    const langNavigation = this.createLanguageNodes(rootState, rootNavigationNode, currentLanguage, pathname, query)
    let { navigation: mainNavigation, isActive: menuActive } = this.createMainNavigationNode(mainMenu, pathname)
    let { navigation: subNavigation, isActive: overlayMenuActive } = this.createNavigationNode(subMenu, pathname)
    let subNavigations = []
    mainMenu.menuItems.forEach((menuItem, index) => {
      if (menuItem.subMenu) {
        subNavigations.push(this.createNavigationNode(menuItem.subMenu, pathname))
      } else {
        subNavigations.push(null)
      }
    })

    let loadingState = null
    if (rootState.loadingState === LoadingState.loading) {
      loadingState = <img alt="loading indicator" className="loading-state" src={require('static/img/loading.gif')} />
    } else if (rootState.loadingState === LoadingState.offline) {
      loadingState = <img alt="offline icon" className="loading-state" src={offlineImage} />
    }

    const navTeaser = hero.heroTeasers.map((item, index) => {
      const dateFormat = new Date(item.date)
      const date =
        currentLanguage === 'de' ? moment(dateFormat).format('DD. MMMM YYYY') : moment(dateFormat).format('DD MMMM YYYY')

      if (item._i18nCL) {
        return (
          <SwiperSlide
            className="header__nav__teaser__element"
            key={index}
            style={{ backgroundImage: 'url(' + ModImgTag.addCloudinaryParamsToUrl(item.image.media.url, ['q_60']) + ')' }}
          >
            <div className="header__nav__teaser__element__content">
              <ModImgTag imgObject={item.icon} />

              <div>
                <span className="label header__nav__teaser__element__content__label">
                  {item._i18nCL.category && <>{item._i18nCL.category}</>}
                  {item._i18nCL.category && date && <> - </>}
                  {date && <>{date}</>}
                </span>

                <h3 className="header__nav__teaser__element__content__title" onClick={this.closeMenu}>
                  <ModAnchorTag linkObject={item.link}>{item._i18nCL.headline}</ModAnchorTag>
                </h3>

                <span onClick={this.closeMenu}>
                  <ModAnchorTag linkObject={item.link} linkText={true} className={'link--white'} hasIcon={true}>
                    <FormattedMessage id="global.read-more" defaultMessage="Read more" />
                  </ModAnchorTag>
                </span>
              </div>
            </div>
          </SwiperSlide>
        )
      } else {
        return null
      }
    })

    const getContactLink = () => {
      if (contactNavigationNode._i18nCL) {
        return (
          <div className="header__sidebar__link header__sidebar__link--contact" onClick={this.closeMenu}>
            <ModAnchorTag linkObject={createNodeLinkObject(contactNavigationNode)}>
              <ModSvg icon="mail"></ModSvg>
              <span className="header__sidebar__link__label link">{contactNavigationNode._i18nCL.slug}</span>
            </ModAnchorTag>
          </div>
        )
      } else {
        return null
      }
    }

    const getSearchLink = () => {
      if (searchNavigationNode._i18nCL) {
        return (
          <div className="header__sidebar__link header__sidebar__search" onClick={this.closeMenu}>
            <ModAnchorTag linkObject={createNodeLinkObject(searchNavigationNode)}>
              <ModSvg icon="search"></ModSvg>
              <span className="header__sidebar__link__label link">{searchNavigationNode._i18nCL.slug}</span>
            </ModAnchorTag>
          </div>
        )
      } else {
        return null
      }
    }

    const socialMediaContent = rootState.websiteSettings.socialMedia.socialMedia.reduce((prevItem, item, index) => {
      if (item.hasOwnProperty('image')) {
        prevItem.push(
          <ModAnchorTag key={index} linkObject={item.link}>
            <ModImgTag
              className={'header__nav__social__icon'}
              width={25}
              height={25}
              imgObject={item.image}
              fileFormat={'jpg'}
              visible={true}
            />
          </ModAnchorTag>
        )
      }
      return prevItem
    }, [])

    return (
      <header className="header" id="header">
        {/* {loadingState} */}
        <ModAnchorTag linkObject={createNodeLinkObject(rootNavigationNode)}>
          <figure className="header__logo" onClick={this.closeMenu}>
            <img className={'logo'} src={require('static/img/baer-karrer-logo.svg')} alt="Bär & Karrer" />
          </figure>
        </ModAnchorTag>
        <div className="grid header__topbar">
          {/* <div className="cell small-24 large-16 large-offset-7"> */}
          <div className="cell small-24 large-23 header-navigation">
            {langNavigation}
            <ul ref={this.ref} className={'menu menu--main' + (menuActive ? ' menu--active' : '')}>
              {mainNavigation}
            </ul>
            {subNavigations.map((item, index) => {
              if (item) {
                return (
                  <ul
                    key={`topsub${index}`}
                    className={
                      'menu menu--main main--subnavigation menu--active fadeIn' +
                      (index == this.state.activeSubMenu ? ' go' : ' hide')
                    }
                  >
                    {item.navigation}
                  </ul>
                )
              }
            })}
          </div>
        </div>

        <div className="header__sidebar">
          <div className={'header__hamburger header__hamburger--' + (menuOpen ? 'open' : 'closed')} onClick={this.openMenu}>
            <div className="header__hamburger__lines"></div>
          </div>

          <div className={'header__sidebar__meta'}>
            {getContactLink()}
            {getSearchLink()}
          </div>
        </div>

        <div className="header__nav" id="header__nav">
          <div className="header__nav__left">
            <div>
              <ul className={'menu menu--main' + (overlayMenuActive || menuActive ? ' menu--active' : '')}>
                {mainNavigation.map((menuItem, index) => {
                  return (
                    <React.Fragment key={`frag${index}`}>
                      {menuItem}
                      {subNavigations[index] && (
                        <ul
                          key={`sidesub${index}`}
                          className={
                            'menu main-side-subnavigataion' +
                            (subNavigations[index].isActive ? ' menu--active' : '') +
                            (index == this.state.activeSubMenu ? ' show' : ' hide')
                          }
                        >
                          {subNavigations[index].navigation}
                        </ul>
                      )}
                    </React.Fragment>
                  )
                })}
              </ul>
              <ul className={'menu menu--meta' + (overlayMenuActive || menuActive ? ' menu--active' : '')}>{subNavigation}</ul>
              <div className="header__nav__lang">{langNavigation}</div>
              <div className="header__nav__social">{socialMediaContent}</div>
            </div>
          </div>
          <div className="header__nav__right">
            <div className="header__nav__teaser" id="header__nav__teaser">
              <div className="swiper-container-nav">
                <div className="swiper-wrapper">
                  <Swiper
                    effect="fade"
                    fadeEffect={{ crossFade: true }}
                    spaceBetween={0}
                    watchOverflow={true}
                    pagination={{
                      clickable: true,
                    }}
                    slidesPerView={1}
                  >
                    {navTeaser}
                  </Swiper>
                </div>
              </div>
            </div>
            <div className="header__nav__layer" id="header__nav__layer"></div>
          </div>
        </div>
      </header>
    )
  }
}
