import { BlockHeader } from 'interfaces/Interfaces'
import * as React from 'react'
import RootState from 'vo/RootState'
import ModBlockHeader from './blocks/ModBlockHeader'
import { stringToRichText } from 'common/Utils'

export interface Mod404Props {
  displaySize: string
  rootState: RootState
  templateData: any[]
}

export default class Mod404 extends React.Component<Mod404Props, {}> {
  render() {
    const { displaySize, rootState, templateData } = this.props
    const get404Header: BlockHeader = {
      _id: '0',
      internalDescription: '404',
      items: [
        {
          _i18nCL: {
            title: stringToRichText('The page you requested was not found'),
          },
        },
      ],
    }

    return (
      <React.Fragment>
        <ModBlockHeader content={get404Header} displaySize={displaySize}></ModBlockHeader>
      </React.Fragment>
    )
  }
}
