import * as React from 'react'
import { injectIntl } from 'react-intl'
import ModSvg from './ModSvg'

export interface ModFilterSearchInputState {
  value: string
}

export interface ModFilterSearchInputProps {
  searchValue: string
  onSearchDelete: (event) => void
  onSearchString: (event) => void
  intl: any
}

class ModFilterSearchInput extends React.Component<ModFilterSearchInputProps, ModFilterSearchInputState, {}> {
  ref = null

  constructor(props) {
    super(props)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      value: this.props.searchValue,
    }
    this.ref = React.createRef()
  }

  componentDidMount() {}

  componentDidUpdate() {}

  handleChange(event) {
    this.setState({ value: event.target.value })
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.props.onSearchString(this.state.value)
    }
  }

  render() {
    const { intl } = this.props

    let getFilterIcon = () => {
      if (this.props.searchValue) {
        return (
          <span
            onClick={(e) => {
              this.props.onSearchDelete(e)
              this.setState({ value: '' })
            }}
          >
            <ModSvg icon="bold-close" />
          </span>
        )
      } else {
        return (
          <span
            className={'filter__element__icon__search'}
            onClick={(e) => {
              this.props.onSearchString(this.state.value)
            }}
          >
            <ModSvg icon="search" />
          </span>
        )
      }
    }

    return (
      <div className={'filter__element' + (this.state.value.length > 0 ? ' is-active' : '')} id="filter__input">
        <input
          ref={this.ref}
          className="filter__input"
          type="search"
          placeholder={intl.formatMessage({ id: 'filter.search', defaultMessage: 'Search' })}
          id="filter__input__search"
          onKeyPress={this.handleKeyPress}
          onChange={this.handleChange}
          value={this.state.value}
        ></input>
        <div className="filter__element__icon">{getFilterIcon()}</div>
      </div>
    )
  }
}

export default injectIntl(ModFilterSearchInput)
