import { SerializedMedia as KarmaMedia, CloudinaryResponse } from '@karma.run/editor-media-common'
import { string } from 'prop-types'

export type CloudinaryMedia = KarmaMedia<{ cloudinary: CloudinaryResponse }>

export interface IStateController {
  publicConfig: IPublicSettings
  editMode: boolean
  cookies: {
    cookie_consent_dismissed: boolean
  }
  domain: Domain
  websiteSettings: Website
  pages: {
    [type: string]: {
      [key: string]: IPage
    }
  }
  currentPage?: {
    type: string
    id: string
  }
}

export interface Website {
  internalDescription: string
  _i18nCL: {
    title: string
    siteName: string
    description: string
    cookiePolicy: string
  }
  googleAnalyticsProperty: string
  googleTagManagerKey: string
  cookiebotId: string
  socialMedia: SocialMedia
  footer: Footer
  rootNavigationNode?: NavigationNode
  contactNavigationNode: NavigationNode
  searchNavigationNode: NavigationNode
  lawyersOverviewNavigationNode: NavigationNode
  newsOverviewNavigationNode: NavigationNode
  publicationsOverviewNavigationNode: NavigationNode
  expertiseOverviewNavigationNode: NavigationNode
  navigationHero: NavigationHero
  menu: Menu
  submenu: Menu
  translations: Translations
  shareImage?: Media
  blocks404: any[]
}

export interface SitemapEntry {
  url: string
  lastUpdated: string
  altUrls?: {
    url: string
    lang: string
  }[]
}

export interface Translations {
  internalDescription: string
  projectId: string
  translations: {
    [key: string]: {
      [key: string]: string
    }
  }
}
export interface RichText {
  blocks: {
    data: any
    depth: number
    entityRanges: any[]
    inlineStyleRanges: any[]
    key: string
    text: string
    type: string
  }[]
  entityMap: any
}

export interface Link {
  document?: Media
  internalLink?: InternalLink
  url?: {
    _i18nCL?: string
    i18n?: any
  }
  staticPage?: {
    lawyer?: Lawyer
    expertise?: Expertise
    news?: News
    publication?: Publication
    language?: string
  }
  boxOverlay?: {
    _i18nCL?: string
  }
}

export interface BlockHeader {
  _id: string
  internalDescription?: string
  defaultTitle?: {
    _i18nCL: {
      subtitle: string
      title: RichText
      linkText: string
    }
  }
  defaultLink?: Link
  items: {
    _i18nCL: {
      subtitle?: string
      title: RichText
      linkText?: string
    }
    link?: Link
    image?: Media
    video?: Media
  }[]
}

export interface BlockTitle {
  _id?: string
  internalDescription?: string
  _i18nCL: {
    title: RichText
  }
}

export interface BlockLead {
  _id?: string
  internalDescription?: string
  _i18nCL: {
    leadText: RichText
  }
}

export interface BlockButtons {
  _id: string
  internalDescription: string
  buttons: {
    _i18nCL: {
      text: string
    }
    link: Link
  }[]
}

export interface blockAccordion {
  _id: string
  internalDescription: string
  accordionType: {
    roadmap: {
      _i18nCL: {
        title: string
      }
      link: Link
    }[]
    faq: {
      richTextItem: {
        _i18nCL: {
          text: RichText
        }
      }
      accordionItem: {
        _i18nCL: {
          text: RichText
          title: string
        }
      }
    }[]
  }
}

export interface blockIconTextGrid {
  _id: string
  internalDescription: string
  items: {
    _i18nCL: {
      title: string
      text: string
    }
    link?: Link
    image: Media
  }[]
  search: SearchEnum
}

export interface blockTeamOffice {
  _id: string
  internalDescription: string
  teamLead: {
    lawyer: Lawyer
    _i18nCL?: {
      title?: string
    }
  }[]
  team: {
    lawyer: Lawyer
  }[]
}

export interface blockTeam {
  _id: string
  internalDescription: string
  team: {
    member: {
      lawyer?: {
        _i18nCL?: {
          title?: string
        }
        lawyer: Lawyer
      }
      other?: {
        _i18nCL?: {
          title?: string
          position?: string
        }
        name: string
        image?: Media
      }
    }
  }[]
  moreTeamMembers: {
    member: {
      lawyer?: {
        _i18nCL?: {
          title?: string
        }
        lawyer: Lawyer
      }
      other?: {
        _i18nCL?: {
          title?: string
          position?: string
        }
        name: string
        image?: Media
      }
    }
  }[]
}

export enum SearchEnum {
  DataProtection = 'Data Protection',
}

export interface blockSearchBox {
  _id: string
  internalDescription: string
  _i18nCL: {
    title: string
    buttonText: string
  }
  search: SearchEnum
}

export interface blockAccordion {
  _id: string
  internalDescription: string
}

export enum RichTextBackground {
  white = 'white',
  gray = 'gray',
}

export interface BlockRichText {
  _id?: string
  internalDescription?: string
  _i18nCL: {
    text: RichText
  }
  background: RichTextBackground
}

export interface BlockImageText {
  _id: string
  internalDescription: string
  _i18nCL: {
    mainTitle: RichText
    title: RichText
    text: RichText
    linkText: string
  }
  link?: Link
  image: Media
}

export interface BlockVideo {
  _id: string
  internalDescription: string
  _i18nCL: {
    youtubeId: string
  }
  autoplay: boolean
}

export interface BlockImage {
  _id: string
  internalDescription: string
  image: Media
  imageSize: string
  caption: {
    _i18nCL: {
      caption: string
    }
  }
}

export interface BlockQuote {
  _id: string
  internalDescription: string
  quotes: Quote[]
}

export enum QuoteTypes {
  neutral = 'neutral',
  client = 'client',
  agentur = 'agentur',
}

export interface Quote {
  type: QuoteTypes
  _i18nCL: {
    quote: string
    source: string
  }
}

export interface BlockLink {
  _id: string
  internalDescription: string
  _i18nCL: {
    title: string
  }
  link: Link
}

export interface BlockTextGrid {
  _id: string
  internalDescription: string
  items: {
    _i18nCL: {
      title: RichText
      text: RichText
    }
  }[]
}

export interface BlockImageGrid {
  _id: string
  internalDescription: string
  items: {
    _i18nCL: {
      title: string
    }
    link: Link
    image: Media
  }[]
}

export interface BlockInNumbers {
  _id: string
  internalDescription: string
  _i18nCL: {
    title: string
  }
  numbers: {
    _i18nCL: {
      number: string
      text: string
    }
  }[]
}

export interface BlockAwards {
  _id: string
  internalDescription: string
  _i18nCL: {
    title: string
  }
  items: {
    _i18nCL: {
      award: string
      years: string
    }
  }[]
}

export interface BlockHistory {
  _id: string
  internalDescription: string
  _i18nCL: {
    title: string
  }
  items: {
    _i18nCL: {
      title: string
      text: string
    }
  }[]
}

export interface BlockExpertiseGrid {
  _id: string
  internalDescription: string
  category: ExpertiseCategory
  _i18nCL: {
    title: string
  }
  expertise: Expertise[]
}

export enum BlockNewsPublicationsGridType {
  news = 'deals & cases',
  publications = 'publications',
}

export interface BlockNewsPublicationsGrid {
  _id?: string
  internalDescription?: string
  type: BlockNewsPublicationsGridType
  _i18nCL: {
    title: string
  }
  items: (News | Publication)[]
  moreLink: Link
  moreLinkText?: string
}

export interface BlockSlider {
  _id: string
  internalDescription: string
  list: {
    _i18nCL: {
      text: string
    }
    image: Media
  }[]
}

export interface BlockIFrame {
  _id: string
  internalDescription: string
  _i18nCL: {
    src: string
  }
  i18n: {
    [key: string]: {
      src: string
    }
  }
  height?: number
}

export interface BlockGraphic {
  _id: string
  internalDescription: string
  graphic: Media
}

export interface BlockBoxGrid {
  _id?: string
  internalDescription?: string
  gridSize: '3' | '4'
  items: {
    _i18nCL: {
      title: string
      text: RichText
    }
    buttons: {
      _i18nCL: {
        title: string
      }
      link: Link
    }[]
  }[]
}

export interface QueryParam {
  key: string
  value: string
}
export interface InternalLink {
  node: NavigationNode | string
  language?: string
  queryParams?: QueryParam[]
}

export interface SocialMedia {
  internalDescription: string
  socialMedia: {
    image: Media
    link: Link
  }[]
}

export interface Footer {
  internalDescription: string
  _i18nCL: {
    title: string
  }
  menu: Menu
  metamenu: Menu
}

export interface Media {
  _id: string
  _type: string
  internalDescription: string
  media: CloudinaryMedia
  _i18nCL: {
    title: string
    altText: string
    media?: CloudinaryMedia
  }
}

export interface NavigationNode {
  _id: string
  internalDescription?: string
  i18n: {
    [key: string]: {
      slug: string
      relativeUrl: string
    }
  }
  _i18nCL: {
    slug: string
    relativeUrl: string
  }
  children: NavigationNode[]
  pageId: string
  page?: Page
}

export enum PageType {
  page = 'page',
  lawyer = 'lawyer',
  expertise = 'expertise',
  news = 'news',
  publication = 'publication',
}
export interface IPage {
  _id: string
  _type: string
  _pageType: PageType
  loadedDateTime: string
}

export interface SearchResultEntry {
  title: string
  description: string
  label: string
  url: string
  imageUrl: string
}
export interface SearchResult {
  total: number
  length: number
  offset: number
  pages: number
  entries: SearchResultEntry[]
}

export enum FilterType {
  news = 'news',
  publication = 'publication',
  lawyer = 'lawyer',
}

export interface FilterResult {
  total: number
  length: number
  offset: number
  entries: StaticPage[]
  error?: string
}

export interface FilterResultLawyer extends FilterResult {
  entries: Lawyer[]
}

export interface FilterResultNewsPublication extends FilterResult {
  entries: NewsPublication[]
}

export interface FilterResultNews extends FilterResultNewsPublication {
  entries: News[]
}

export interface FilterResultPublication extends FilterResultNewsPublication {
  entries: Publication[]
}

export interface FilterOption {
  value: string
  text?: string
  textIntlId?: string
}

export interface Filter {
  queryKey: string
  titleIntlId: string
  options: FilterOption[]
}

export interface TemplateOffice {
  office: Office
  blockLead: BlockLead
  practices: Expertise[]
  sectors: Expertise[]
  lawyersLink: Link
  blocks: any[]
}

export interface TemplateOffice2 {
  office: Office
  blockLead: BlockLead
  blockTeam: blockTeamOffice
  practices: Expertise[]
  sectors: Expertise[]
  lawyersLink: Link
  blocks: any[]
}
export interface Page extends IPage {
  internalDescription: string
  blockHeader: BlockHeader
  i18n: {
    [key: string]: {
      title: string
      description: string
      isLive: boolean
    }
  }
  _i18nCL: {
    title: string
    description: string
    isLive: boolean
  }
  publishDate?: string
  expiryDate?: string
  navigationNode?: NavigationNode | string
  shareImage?: Media
  content: {
    templateBlockList: any[]
    templateLawyerOverview: {
      blocks?: any[]
      result: FilterResultLawyer
      filters: Filter[]
    }
    templatePublicationOverview: {
      blocks?: any[]
      result: FilterResultPublication
      filters: Filter[]
    }
    templateNewsOverview: {
      blocks?: any[]
      result: FilterResultNews
      filters: Filter[]
    }
    templateOffice: TemplateOffice
    templateOffice2: TemplateOffice2
    templateSearch: {
      blocks?: any[]
    }
    templateDataProtection: {
      blocks: any[]
      nextPage?: NavigationNode
      prevPage?: NavigationNode
    }
  }
}

export interface StaticPage extends IPage {
  i18n: {
    [key: string]: {
      slug: string
      relativeUrl: string
    }
  }
  _i18nCL: {
    slug: string
    relativeUrl: string
  }
}

export interface Lawyer extends StaticPage {
  internalDescription: string
  headerImage?: Media
  teaserImage?: Media
  position: LawyerPosition
  offices: Office[]
  languages: LawyerLanguage[]
  _i18nCL: {
    slug: string
    relativeUrl: string
    description: RichText
    recentWorkHighlights: RichText
    curriculum: RichText
    memberships: RichText
    admission: RichText
    academicTitle: string
  }
  contact: {
    firstName: string
    lastName: string
    phone: string
    mobile: string
    fax: string
    email: string
    linkedIn: string
    academicTitle: string
    admission: string
  }
  assistants: Lawyer[]
  expertise: Expertise[]
  expertise_ordered: {
    fixOrder: boolean
    key: Expertise[]
    leader: Expertise[]
    member: Expertise[]
  }
  expertiseLink: Link
  news: News[]
  newsLink: Link
  publications: Publication[]
  publicationsLink: Link
  speakingEngagements: Publication[]
  speakingEngagementsLink: Link
  quotes: BlockQuote //Quote[];
  clientQuote: BlockQuote //Quote[];
  hideLawyer: boolean
}

export interface LawyerPosition {
  _i18nCL: {
    title: string
    shortCode: string
  }
  items?: {
    _i18nCL: {
      title: string
    }
    office: Office
  }[]
}

export interface LawyerPositionOrder {
  items: {
    _i18nCL: {
      title: string
      shortCode: string
    }
    positions: {
      position: LawyerPosition
    }[]
  }[]
}

export interface LawyerLanguage {
  _i18nCL: {
    title: string
  }
}

export interface NewsPublication extends StaticPage {
  internalDescription: string
  date: string
  _i18nCL: {
    slug: string
    relativeUrl: string
    seoDescription: string
    title: RichText
    teaserText: string
    leadText: RichText
  }
  category: NewsCategory | PublicationCategory
  image: Media
  content: any[]
  expertise: Expertise[]
  mainAuthors: MainAuthors[]
  furtherAuthors: Lawyer[]
  moreLink: Link
}

export interface MainAuthors {
  external?: {
    name: string
    image?: Media
  }
  internal?: Lawyer
}

export enum NewsCategory {
  dealsAndCases = 'dealsAndCases',
  corporateNews = 'corporateNews',
  event = 'event',
}

export interface News extends NewsPublication {
  category: NewsCategory
  furtherNews: News[]
}

export enum PublicationCategory {
  briefing = 'briefing',
  publication = 'publication',
  speakingEngagement = 'speakingEngagement',
  guide = 'guide',
  legalInsight = 'legalInsight',
}

export interface Publication extends NewsPublication {
  category: PublicationCategory
  furtherPublications: Publication[]
  guide?: Guide
}

export interface Guide extends NewsPublication {
  logo: Media
}

export enum ExpertiseCategory {
  sector = 'sector',
  practice = 'practice',
}

export interface ExpertiseI18n {
  slug: string
  relativeUrl: string
  title: RichText
  headerTitle: RichText
  leadText: RichText
  description: RichText
  seoDescription: string
  keyContactsTitle: string
  teamLeadersTitle: string
  teamMembersTitle: string
}

export interface Expertise extends StaticPage {
  expertise: Quote
  internalDescription: string
  category: ExpertiseCategory
  _i18nCL: ExpertiseI18n
  richText?: BlockRichText
  buttons?: BlockButtons
  quote?: BlockQuote
  keyContacts: {
    keyContact?: {
      lawyer: Lawyer
      showExpertiseInLawyerProfile: boolean
    }
    topicContact?: {
      contact: Lawyer
      showExpertiseInLawyerProfile: boolean
      _i18nCL: {
        topic: string
      }
    }
  }[]
  teamLeaders: {
    _i18nCL: {
      topic: string
    }
    lawyer: Lawyer
    showExpertiseInLawyerProfile: boolean
  }[]
  teamMembers: {
    _i18nCL: {
      topic: string
    }
    lawyer: Lawyer
    showExpertiseInLawyerProfile: boolean
  }[]
  ranking?: BlockQuote
  publications: Publication[]
  publicationsLink: Link
  news: News[]
  newsLink: Link
}

export interface Menu {
  _id: string
  internalDescription?: string
  menuItems: [
    {
      i18n: {
        [key: string]: {
          label: string
        }
      }
      _i18nCL: {
        label: string
      }
      link: Link
      subMenu?: Menu
    }
  ]
  children: NavigationNode[]
  pageId: string
}

export interface NavigationHero {
  _id: string
  internalDescription?: string
  heroTeasers: [
    {
      image: Media
      icon: Media
      date: string
      _i18nCL: {
        category: string
        headline: string
        linkText: string
      }
      link?: Link
    }
  ]
}

export interface Coordinates {
  lat: number
  long: number
}

export interface Office {
  _id: string
  internalDescription: string
  _i18nCL: {
    address: {
      name: string
      street: string
      city: string
      country: string
    }
    title: RichText
    shortCode: string
  }
  googleUrl: string
  location: Coordinates
  phone: string
  fax: string
  email: string
  directionPdf?: Media
}

export interface IPublicSettings {
  cloudinaryCloudName: string
  mediaProxy: string
  caasEditorUrl: string
}

export interface Domain {
  internalDescription: string
  domains: string[]
  forwards: {
    pattern: string
    regex: boolean
    target: {
      navigationNode: {
        node: string
      }
      url: string
    }
  }[]
  serve?: Website | string
}
