import * as React from 'react'
import { BlockGraphic } from 'interfaces/Interfaces'
import ModImgTag from 'components/common/ModImgTag'
import ModAnimation from 'components/common/ModAnimation'

export interface ModBlockGraphicProps {
  content: BlockGraphic
}

export default class ModBlockGraphic extends React.Component<ModBlockGraphicProps> {
  constructor(props) {
    super(props)
  }

  render() {
    const { content } = this.props
    return (
      <div className="block-graphic grid align-center">
        <ModAnimation className="cell small-24">
          <div className="block-graphic__image-wrapper">
            <ModImgTag
              className="block-graphic__image"
              imgObject={content.graphic}
              width={1280}
              height={720}
              transformation={'c_fit'}
            />
          </div>
        </ModAnimation>
      </div>
    )
  }
}
