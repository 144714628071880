import * as React from 'react'
import { ReactNode, useState } from 'react'
import { CookieContext } from '../common/CookieContext'
import { setJsonCookie, getCookieObject } from '../common/Utils'

export interface ClientCookieProviderProps {
  children: ReactNode
}

export function ClientCookieProvider({ children }: ClientCookieProviderProps) {
  const [cookies, setCookies] = useState(getCookieObject())

  function setCookie(key: string, value: any): void {
    setCookies({ ...cookies, [key]: value })
    setJsonCookie(key, value)
  }

  return <CookieContext.Provider value={{ cookies, setCookie }}>{children}</CookieContext.Provider>
}
