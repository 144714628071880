import * as React from 'react'
import { blockSearchBox } from '../../interfaces/Interfaces'
import ModAnimation from '../common/ModAnimation'
import { injectIntl, FormattedMessage } from 'react-intl'
import { NavigationNodeContext } from 'components/ModApp'
import { searchQueryParams } from 'vo/SearchQueryParams'

export interface ModBlockSearchBoxProps {
  content: blockSearchBox
  currentLanguage: string
  intl: any
}

function ModBlockSearchBox(props: ModBlockSearchBoxProps) {
  const searchInput = React.useRef(null)
  const { content, currentLanguage } = props
  const { location, router, searchNavigationNode } = React.useContext(NavigationNodeContext)

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()

      onSearch()
    }
  }

  function onSearch() {
    const searchType = content.search.toLowerCase().replace(' ', '')

    let params = new URLSearchParams()
    params.set('term', searchTerm)
    params.set('type', searchType)

    router.push({
      pathname: searchNavigationNode._i18nCL.relativeUrl,
      query: params,
    })
  }

  const [searchTerm, setSearchTerm] = React.useState(() => {
    if (!onSearch) return ''
    let term = ''
    if (location.query) {
      term = location.query.get(searchQueryParams.term) || ''
    }
    if (term !== '') onSearch()
    return term
  })

  return (
    <div className="block-search-box grid align-center">
      <div className="cell small-24 large-20 xlarge-18">
        <ModAnimation>
          <h2 className={'block-search-box__title'}>{content._i18nCL.title}</h2>
          <input
            ref={(t) => (searchInput.current = t)}
            type="search"
            placeholder={props.intl.formatMessage({ id: 'filter.search', defaultMessage: 'Search' })}
            onKeyPress={(event) => handleKeyPress(event)}
            onChange={(event) => setSearchTerm(event.target.value)}
            value={searchTerm}
          ></input>
          <div
            className="button button--hollow"
            onClick={() => {
              onSearch()
            }}
          >
            <FormattedMessage id="filter.search" defaultMessage="Search" />
          </div>
        </ModAnimation>
      </div>
    </div>
  )
}

export default injectIntl(ModBlockSearchBox)
