import 'babel-polyfill'
import * as React from 'react'
import { hydrate, render } from 'react-dom'
import App from './App'

require('intersection-observer')
require('svg4everybody')()

declare var DEVELOPMENT: string
if (typeof DEVELOPMENT !== 'undefined' && DEVELOPMENT === 'development') {
  require('../static/scss/index_external.scss')
}

hydrate(<App />, document.getElementById('app-wrapper'))
