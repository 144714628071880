import moment = require('moment')
import * as React from 'react'
import { IntlProvider, addLocaleData } from 'react-intl'
import * as isoFetch from 'isomorphic-fetch'
import ModContent from './ModContent'
import { createMetaFields } from 'common/MetaFields'
import { NavigationNode, IPage } from '../interfaces/Interfaces'
import ModNavigation from './ModNavigation'
import ModFooter from './ModFooter'

import { getCurrentLanguageOrFallBackByPath } from 'common/Languages'
import { addClassToElement, addCurrentLangRecursive, removeClassFromElement } from 'common/Utils'

import * as en from 'react-intl/locale-data/en'
import * as de from 'react-intl/locale-data/de'
import * as fr from 'react-intl/locale-data/fr'
import RootState, { LoadingState } from '../vo/RootState'
import CmsRouter, { RouterLocation, RouterLocationExtended } from '../control/CmsRouter'
import { searchNavigationNodeByUrl } from '../common/CmsUtils'

addLocaleData([...en, ...de, ...fr])

export interface ModAppProps {
  location: RouterLocationExtended
  router: CmsRouter
  APP_PROPS?: RootState
}

const defaultContext: {
  lang: string
  location: RouterLocationExtended
  router: CmsRouter
  rootNavigationNode: NavigationNode
  searchNavigationNode: NavigationNode
} = {
  lang: '',
  router: null,
  location: {
    pathname: '',
    query: null,
    href: '',
    action: '',
    pageYOffset: 0,
  },
  rootNavigationNode: null,
  searchNavigationNode: null,
}

const defaultNavigation: NavigationNode = null

export const NavigationNodeContext = React.createContext(defaultContext)

export default class ModApp extends React.Component<ModAppProps, RootState> {
  constructor(props) {
    super(props)

    const currentLanguage = getCurrentLanguageOrFallBackByPath(this.props.location.pathname)

    if (typeof window !== 'undefined') {
      // runs in frontend
      if (window.hasOwnProperty('APP_PROPS')) {
        const rootState: RootState = Object.assign(new RootState(), window['APP_PROPS'])
        addCurrentLangRecursive(rootState, currentLanguage)
        this.state = rootState
      }
    } else if (this.props.APP_PROPS) {
      // runs in backend
      this.state = this.props.APP_PROPS
      addCurrentLangRecursive(this.state, currentLanguage)
    }

    this.setStateAndCurrentLang = this.setStateAndCurrentLang.bind(this)
    this.setStateBy = this.setStateBy.bind(this)
    this.setHeadMetaInformation = this.setHeadMetaInformation.bind(this)
    this.hasUpdatedLocation = this.hasUpdatedLocation.bind(this)
    this.onCloseModalView = this.onCloseModalView.bind(this)
    this.setBodyState = this.setBodyState.bind(this)
    this.onDisplaySizeChange = this.onDisplaySizeChange.bind(this)
  }

  onCloseModalView() {
    this.props.router.push({
      hash: '',
      pathname: this.props.location.pathname,
      query: null,
    })
  }

  setStateAndCurrentLang(newState) {
    const currentLanguage = getCurrentLanguageOrFallBackByPath(this.props.location.pathname)
    newState = Object.assign(JSON.parse(JSON.stringify(this.state)), newState) // TODO refactoring
    addCurrentLangRecursive(newState, currentLanguage)
    this.setState(newState)
  }

  setHeadMetaInformation() {
    const currentLanguage = getCurrentLanguageOrFallBackByPath(this.props.location.pathname)
    const metaFields = createMetaFields(this.state, currentLanguage)
    document.title = metaFields.title
  }

  setStateBy(replaceObjects) {
    this.setState(Object.assign({}, this.state, replaceObjects))
  }

  hasUpdatedLocation(lastProps, nextProps) {
    try {
      if (lastProps.location.href !== nextProps.location.href) {
        return true
      }
    } catch (e) {}
    return false
  }

  isUpToDate(dateTime: string): boolean {
    return new Date().getTime() - 1000 * 60 < new Date(dateTime).getTime()
  }

  componentWillUpdate(nextProps: ModAppProps, nextState: RootState) {
    if (this.hasUpdatedLocation(this.props, nextProps)) {
      const prevLanguage = getCurrentLanguageOrFallBackByPath(this.props.location.pathname)
      const currentLanguage = getCurrentLanguageOrFallBackByPath(nextProps.location.pathname)
      const nextNode = searchNavigationNodeByUrl(
        this.state.websiteSettings.rootNavigationNode,
        nextProps.location.pathname,
        currentLanguage
      )

      let isUpToDate = false
      //TODO: fix this
      // if (
      //   nextNode &&
      //   this.state.pages.hasOwnProperty(nextNode.pageId) &&
      //   this.state.pages[nextNode.pageId].loadedDateTime
      // ) {

      //   isUpToDate = this.isUpToDate(this.state.pages[nextNode.pageId].loadedDateTime)
      // }

      // if (isUpToDate) {
      //   this.setState({
      //     currentPageId: nextNode.pageId
      //   })
      //   return
      // } else {
      this.setState({
        loadingState: LoadingState.loading,
      })
      // }

      let fetchParams = new URL(nextProps.location.href).searchParams
      fetchParams.append('path', nextProps.location.pathname)
      const paramsString = fetchParams.toString()

      isoFetch('/api/content?' + paramsString)
        .then(function (response) {
          return response.json()
        })
        .then((page: IPage) => {
          let pages = Object.assign({}, this.state.pages)
          if (!pages[page._pageType]) pages[page._pageType] = {}
          pages[page._pageType][page._id] = page

          const newSate = {
            loadingState: LoadingState.idle,
            currentPage: {
              type: page._pageType,
              id: page._id,
            },
            pages,
          }
          if (currentLanguage != prevLanguage) {
            this.setStateAndCurrentLang(newSate)
          } else {
            this.setState(newSate)
          }
        })
        .catch((error) => {
          console.error(error)
          this.setState({
            loadingState: LoadingState.offline,
            currentPage: null,
          })
        })
    }
  }

  componentDidMount() {
    this.toggleEditMode()
    this.setBodyState()
    window.addEventListener('resize', this.onDisplaySizeChange)
    this.onDisplaySizeChange()
  }

  onDisplaySizeChange() {
    let dispSize = ''
    if (window.innerWidth >= 1024) {
      dispSize = 'desktop'
    } else if (window.innerWidth >= 768) {
      dispSize = 'tablet'
    } else {
      dispSize = 'mobile'
    }
    if (this.state.displaySize != dispSize) {
      this.setState({ displaySize: dispSize })
    }
  }

  /**
   * activates edit mode which displays direct link to the karma editor
   */
  toggleEditMode() {
    if (typeof window !== 'undefined') {
      let keysDown = {}
      let switchMode = false

      function isValidKey(key) {
        return key === 'Control' || key === 'Shift' || key === 'E'
      }

      document.addEventListener(
        'keydown',
        (event) => {
          if (isValidKey(event.key)) {
            keysDown[event.key] = true
            if (Object.keys(keysDown).length === 3) {
              switchMode = true
            }
          }
        },
        false
      )
      document.addEventListener(
        'keyup',
        (event) => {
          if (isValidKey(event.key)) {
            delete keysDown[event.key]
            if (switchMode) {
              switchMode = false
              this.setState({ editMode: !this.state.editMode })
            }
          }
        },
        false
      )
    }
  }

  setBodyState() {
    if (typeof window !== 'undefined') {
      const body = document.getElementsByTagName('BODY')[0]

      if (this.props.location.query && this.props.location.query.has('overlay')) {
        addClassToElement(body, 'overflow-hidden')
      } else {
        removeClassFromElement(body, 'overflow-hidden')
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props
    this.setHeadMetaInformation()

    if (
      typeof window !== 'undefined' &&
      this.state &&
      this.state.loadingState !== LoadingState.loading &&
      prevState &&
      prevState.currentPage &&
      (!this.state.currentPage || prevState.currentPage.id !== this.state.currentPage.id)
    ) {
      if (location.pageYOffset >= 0) {
        window.scrollTo(0, location.pageYOffset)
      }
    }
    this.setBodyState()
  }

  render() {
    if (!this.state) {
      return (
        <div id="app">
          <p className="loading">loading</p>
        </div>
      )
    }

    const { websiteSettings } = this.state
    const { rootNavigationNode, contactNavigationNode, searchNavigationNode, menu, submenu, navigationHero } = websiteSettings
    const currentLanguage = getCurrentLanguageOrFallBackByPath(this.props.location.pathname)
    const translations = websiteSettings.translations.translations[currentLanguage]
    moment.locale(currentLanguage)

    return (
      <IntlProvider locale={currentLanguage} messages={translations}>
        <NavigationNodeContext.Provider
          value={{
            lang: currentLanguage,
            location: this.props.location,
            router: this.props.router,
            rootNavigationNode: rootNavigationNode,
            searchNavigationNode: searchNavigationNode,
          }}
        >
          <div id="app">
            <ModNavigation
              rootNavigationNode={rootNavigationNode}
              contactNavigationNode={contactNavigationNode}
              searchNavigationNode={searchNavigationNode}
              mainMenu={menu}
              subMenu={submenu}
              hero={navigationHero}
              currentLanguage={currentLanguage}
              rootState={this.state}
              pathname={this.props.location.pathname}
              query={this.props.location.query}
            />
            <ModContent rootState={this.state} currentLanguage={currentLanguage} displaySize={this.state.displaySize} />
            <ModFooter
              content={this.state.websiteSettings.footer}
              socialMedia={this.state.websiteSettings.socialMedia}
              currentLanguage={currentLanguage}
            />
          </div>
        </NavigationNodeContext.Provider>
      </IntlProvider>
    )
  }
}
