import * as React from 'react'
import { BlockVideo } from '../../interfaces/Interfaces'

import ModAnimation from '../common/ModAnimation'
import { FormattedMessage } from 'react-intl'

export interface ModBlockVideoProps {
  content: BlockVideo
}

export default function ModBlockVideo({ content }: ModBlockVideoProps) {
  if (!(content._i18nCL && content._i18nCL.youtubeId)) {
    return null
  }
  const i18n = content._i18nCL
  const autoplay = content.autoplay

  return (
    <div className="block-video">
      <div className="grid align-center">
        <ModAnimation className="cell small-24 large-20 xlarge-18">
          <div className="cookieconsent-optout-marketing">
            <div className="cookieconsent-optout-marketing-custom">
              <p>
                <a
                  onClick={() => {
                    // @ts-ignore
                    window.Cookiebot.show()
                  }}
                >
                  <FormattedMessage
                    id="cookiePolicy.acceptMarketing"
                    defaultMessage="Please accept marketing cookies to see this content."
                  />
                </a>
              </p>
            </div>
          </div>
          <div className="block-video__video">
            <iframe
              data-cookieconsent="marketing"
              width="560"
              height="315"
              allow="fullscreen;"
              data-src={
                'https://www.youtube.com/embed/' +
                i18n.youtubeId +
                '?autoplay=' +
                (autoplay ? '1' : '0') +
                '&controls=1&mute=' +
                (autoplay ? '1' : '0') +
                '&rel=0'
              }
            ></iframe>
          </div>
        </ModAnimation>
      </div>
    </div>
  )
}
