import * as React from 'react'
import { PageType, TemplateOffice2 } from '../../interfaces/Interfaces'
import ModBlockLead from 'components/blocks/ModBlockLead'
import ModAnchorTag from 'components/common/ModAnchorTag'
import ModAnimation from 'components/common/ModAnimation'
import { FormattedMessage, injectIntl } from 'react-intl'
import ModTemplateBlockList from './ModTemplateBlockList'
import RootState from 'vo/RootState'
import ModBlockTeamOffice from 'components/blocks/ModBlockTeamOffice'
import { stringToBlockTitleObject, createStaticPageLinkObject } from 'common/CmsUtils'
import ModBlockTitle from 'components/blocks/ModBlockTitle'
import { richTextHasContent, richTextToPlainText } from 'common/Utils'
import ModRichTextFragment from 'components/common/ModRichTextFragment'
import ModSvg from 'components/common/ModSvg'

export interface ModTemplateOfficeProps {
  content: TemplateOffice2
  currentLanguage: string
  rootState: RootState
  intl: any
}

class ModTemplateOffice2 extends React.Component<ModTemplateOfficeProps> {
  sendMail(email: string) {
    window.location.href = 'mailto:' + email
  }

  render() {
    const { content, currentLanguage, rootState, intl } = this.props

    const plainTitle = richTextToPlainText(content.office._i18nCL?.title)
    const getPractiseAreas = () => {
      if (content.practices.length > 0) {
        return (
          <div className="grid office2__expertise align-center">
            <div className="cell small-24 large-20 xlarge-18">
              <ModAnimation>
                <span className="label">
                  <FormattedMessage id="global.practice-areas" defaultMessage="Practice Areas" />
                </span>
              </ModAnimation>
              <ModAnimation>
                <div className="list-wrapper">
                  <ul>{practiseAreas}</ul>
                </div>
              </ModAnimation>
            </div>
          </div>
        )
      } else {
        return null
      }
    }

    const practiseAreas = content.practices.map((item, index) => {
      if (item._i18nCL && richTextHasContent(item._i18nCL.title)) {
        return (
          <ModAnchorTag
            key={item._id}
            className="template-office-link-li"
            linkObject={createStaticPageLinkObject(item, PageType.expertise)}
          >
            <li>
              <ModRichTextFragment richText={item._i18nCL.title} />
            </li>
          </ModAnchorTag>
        )
      } else {
        return null
      }
    })

    const getSectors = () => {
      if (content.sectors.length > 0) {
        return (
          <div className="small-gap grid office2__expertise align-center">
            <div className="cell small-24 large-20 xlarge-18">
              <ModAnimation>
                <span className="label">
                  <FormattedMessage id="global.sectors" defaultMessage="Sectors" />
                </span>
              </ModAnimation>
              <ModAnimation>
                <div className="list-wrapper">
                  <ul>{sectors}</ul>
                </div>
              </ModAnimation>
            </div>
          </div>
        )
      } else {
        return null
      }
    }

    const sectors = content.sectors.map((item, index) => {
      if (item._i18nCL && richTextHasContent(item._i18nCL.title)) {
        return (
          <ModAnchorTag
            key={item._id}
            className="template-office-link-li"
            linkObject={createStaticPageLinkObject(item, PageType.expertise)}
          >
            <li>
              <ModRichTextFragment richText={item._i18nCL.title} />
            </li>
          </ModAnchorTag>
        )
      } else {
        return null
      }
    })

    const getContactInfo = () => {
      if (content.office._i18nCL) {
        return (
          <div className="grid align-center office2__address">
            <ModAnimation className="cell small-24 large-20 xlarge-18">
              <ModAnimation>
                <span className="label">
                  <FormattedMessage id="global.contact" defaultMessage="Contact" />
                </span>
              </ModAnimation>

              <p>
                {content.office._i18nCL.address.name && (
                  <>
                    {' '}
                    <strong>{content.office._i18nCL.address.name}</strong>
                    <br />{' '}
                  </>
                )}
                {content.office._i18nCL.address.street && (
                  <>
                    {' '}
                    {content.office._i18nCL.address.street} <br />{' '}
                  </>
                )}
                {content.office._i18nCL.address.city && (
                  <>
                    {' '}
                    {content.office._i18nCL.address.city} <br />{' '}
                  </>
                )}
                {content.office._i18nCL.address.country && (
                  <>
                    {' '}
                    {content.office._i18nCL.address.country} <br />{' '}
                  </>
                )}
                {content.office.phone && (
                  <>
                    {' '}
                    {'P: '}
                    <a href={'tel:' + content.office.phone}>{content.office.phone}</a>
                    <br />{' '}
                  </>
                )}
                {content.office.fax && (
                  <>
                    {' '}
                    {'F: ' + content.office.fax} <br />{' '}
                  </>
                )}
                {content.office.email && (
                  <>
                    <p
                      className="link link--mail"
                      onClick={() => {
                        this.sendMail(content.office.email)
                      }}
                    >
                      <ModSvg icon="mail" />
                      <FormattedMessage id="lawyer.send-email" defaultMessage="Send e-mail" />
                    </p>
                  </>
                )}
              </p>
            </ModAnimation>
          </div>
        )
      } else {
        return null
      }
    }

    return (
      <div className="office2">
        <ModBlockLead content={content.blockLead} />

        {content.blocks && (
          <ModTemplateBlockList currentLanguage={currentLanguage} templateData={content.blocks} rootState={rootState} />
        )}

        {getPractiseAreas()}
        {getSectors()}

        {!content.blockTeam ? (
          <div className="grid align-center">
            <ModAnimation className="cell small-24 large-20 xlarge-18">
              <ModAnchorTag className="link link--internal" linkObject={content.lawyersLink}>
                <FormattedMessage id="office.all-lawyers" defaultMessage="All Lawyers" />{' '}
                {plainTitle && (
                  <>
                    &nbsp;
                    {'in ' + plainTitle} <br />{' '}
                  </>
                )}
              </ModAnchorTag>
            </ModAnimation>
          </div>
        ) : (
          <>
            <ModBlockTitle
              content={stringToBlockTitleObject(
                intl.formatMessage(
                  {
                    id: 'office.meetTeam',
                    defaultMessage: 'Meet our {place} team below',
                  },
                  {
                    place: plainTitle,
                  }
                )
              )}
            />
            <ModBlockTeamOffice content={content.blockTeam} />
          </>
        )}

        {getContactInfo()}

        {content.office.googleUrl && (
          <div className="grid align-center">
            <ModAnimation className="cell small-24 large-20 xlarge-18">
              <br />
              <a className="link link--internal" href={content.office.googleUrl} target="_blank">
                <FormattedMessage id="office.directions" defaultMessage="Get Directions" />
              </a>
            </ModAnimation>
          </div>
        )}
      </div>
    )
  }
}

export default injectIntl(ModTemplateOffice2)
