import * as React from 'react'
import { BlockImageGrid } from "../../interfaces/Interfaces";
import ModImgTag from "../common/ModImgTag";
import ModAnchorTag from "../common/ModAnchorTag";
import ModAnimation from "../common/ModAnimation";

export interface ModBlockImageGridProps {
  content: BlockImageGrid
}

export default class ModBlockImageGrid extends React.Component<ModBlockImageGridProps, {}> {

  render() {
    const { content } = this.props

    const gridElements = content.items.map((item, index) => {
      if(item._i18nCL){
      return (
        <ModAnimation className="block-image-grid__element cell small-24 medium-12 large-12" key={index}>
          <ModAnchorTag className="block-image-grid__element__link" linkObject={item.link}>
            <ModImgTag className="block-image-grid__element__image" imgObject={item.image} width={960} height={600}/>
            <h2 className="block-image-grid__element__title">{item._i18nCL.title}</h2>
          </ModAnchorTag>
        </ModAnimation>
      )
    }
      else{
        return null;
      }
    })

    return (
      <div className="block-image-grid grid align-center">
        <div className="cell small-24 large-20 xlarge-18">
          <div className="grid">
            {gridElements}
          </div>
        </div>
      </div>
    )
  }
}