import * as React from 'react'
import { injectIntl } from 'react-intl'
import { useInView } from 'react-intersection-observer'


export interface ModFadeInProps {
  className?: string
  children?: React.ReactNode
  type?: string
  tag?: keyof JSX.IntrinsicElements
}

const ModAnimation = (props: ModFadeInProps) => {
  const { type } = props
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true
  })

 const invalidateChildren = (children) => {
    if (typeof children === 'string') {
      return <div className="animation__content">{children}</div>
    }
    else if (typeof children === 'undefined') {
      return null
    }
    return children
  }

  const className =  props.className || ''
  const Tag: any = props.tag || 'div'

  let children = invalidateChildren(props.children)

  return (
    <Tag className={className + ' animation ' + (type == 'headline' ? 'buildUp ' : 'fadeIn ') + (inView ? 'go ' : '')} ref={ref}>
      {children}
    </Tag>
  )
}

export default injectIntl(ModAnimation)