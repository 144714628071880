import * as React from 'react'
import { IsomorphicURLSearchParams } from '../../common/IsomorphicURL'

import { Link, NavigationNode, PageType } from '../../interfaces/Interfaces'
import ModSvg from './ModSvg'
import NavLink from './NavLink'
import { NavigationNodeContext } from '../ModApp'
import { getCurrentLanguageOrFallBackByPath } from '../../common/Languages'
import { findNavigationNodeById } from '../../common/CmsUtils'
import { geti18nClIfMissing } from './Utils'
import ModToolTip from './ModToolTipItem'

const IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'svg']

export interface ModAnchorTagProps {
  linkObject: Link
  hasIcon?: boolean
  className?: string
  linkText?: boolean
}

export default class ModAnchorTag extends React.Component<ModAnchorTagProps, {}> {
  static getLinkIconType(linkObject, hasIcon) {
    if (!hasIcon) return null
    let iconType = null
    if (linkObject.hasOwnProperty('url')) {
      // iconType = <img src={require("static/img/svg/link-external.svg")} id="icon" />
      iconType = <ModSvg icon="url" />
    } else if (linkObject.hasOwnProperty('document')) {
      iconType = <ModSvg icon="download" />
    }
    return iconType
  }

  static invalidateChildren(children) {
    if (typeof children === 'string') {
      return <span>{children}</span>
    } else if (typeof children === 'undefined') {
      return null
    }
    return children
  }

  render() {
    let children = ModAnchorTag.invalidateChildren(this.props.children)

    if (!(typeof this.props.linkObject === 'object')) {
      return children
    }

    const { linkObject, hasIcon } = this.props

    let className = ''
    if (this.props.className) {
      className = this.props.className
    }

    try {
      const linkIcon = ModAnchorTag.getLinkIconType(linkObject, hasIcon)
      if (linkObject.hasOwnProperty('url')) {
        return (
          <NavigationNodeContext.Consumer>
            {({ lang }) => {
              // // IT: fallback -> if no italian translation, use english
              // // if the language is italian and the relativeUrl is not present, try to get the english one
              // // since it isn't fully implemented
              // if (lang === 'it') {
              //   if (!linkObject.url._i18nCL || linkObject.url._i18nCL === '') {
              //     if (linkObject.url.i18n.en !== '') {
              //       linkObject.url._i18nCL = linkObject.url.i18n.en
              //     }
              //   }
              // }

              if (!linkObject.url._i18nCL || linkObject.url._i18nCL === '') {
                return children
              }
              const i18nCL = linkObject.url._i18nCL
              if (i18nCL.startsWith('mailto:')) {
                return (
                  <a className={'a-mail ' + className} href={i18nCL}>
                    {linkIcon}
                    {children}
                  </a>
                )
              }

              if (this.props.linkText) {
                className = 'link link--external ' + className
              }
              var path = require('path')
              if (path.isAbsolute(i18nCL)) {
                //split path and query params
                let components = i18nCL.split('?')
                let pathname = components[0]
                let searchParams = null
                if (components.length > 1) {
                  let searchParamString = components[1]
                  searchParams = new URLSearchParams(searchParamString)
                }

                return (
                  <NavLink
                    className={'a-internal ' + className}
                    to={{
                      pathname: pathname,
                      query: searchParams,
                    }}
                  >
                    {linkIcon}
                    {children}
                  </NavLink>
                )
              } else {
                return (
                  <a className={'a-external ' + className} href={i18nCL} target="_blank" rel="noopener">
                    {linkIcon}
                    {children}
                  </a>
                )
              }
            }}
          </NavigationNodeContext.Consumer>
        )
      } else if (linkObject.hasOwnProperty('internalLink')) {
        const internalLink = linkObject.internalLink

        return (
          <NavigationNodeContext.Consumer>
            {({ location, rootNavigationNode }) => {
              let navigationNode: NavigationNode
              if (typeof internalLink.node === 'string') {
                navigationNode = findNavigationNodeById(internalLink.node, rootNavigationNode)
              } else {
                navigationNode = findNavigationNodeById(internalLink.node._id, rootNavigationNode)
              }

              if (!navigationNode) {
                console.warn(`navigationNode ${internalLink.node} not found`)
                return children
              }

              if (this.props.linkText) {
                className = 'link link--internal ' + className
              }

              let url = navigationNode._i18nCL.relativeUrl

              // IT: fallback -> if no italian translation, use english
              // since it isn't fully implemented we go to the english homepage
              if (url === '/it') {
                url = '/en'
              }

              if (internalLink.language && navigationNode.i18n && navigationNode.i18n.hasOwnProperty(internalLink.language)) {
                url = navigationNode.i18n[internalLink.language].relativeUrl
              }

              let locationTo = {
                pathname: url,
              }
              if (internalLink.queryParams) {
                let queryParams = new IsomorphicURLSearchParams()
                internalLink.queryParams.forEach((queryParam) => {
                  queryParams.append(queryParam.key, queryParam.value)
                })
                locationTo['query'] = queryParams
              }
              return (
                <NavLink className={'a-internal ' + className} to={locationTo}>
                  {linkIcon}
                  {children}
                </NavLink>
              )
            }}
          </NavigationNodeContext.Consumer>
        )
      } else if (linkObject.hasOwnProperty('staticPage')) {
        return (
          <NavigationNodeContext.Consumer>
            {({ lang }) => {
              const page = linkObject.staticPage
              let relativeUrl
              let type
              if (page.hasOwnProperty('lawyer')) {
                type = 'lawyer'
              } else if (page.hasOwnProperty('expertise')) {
                type = 'expertise'
              } else if (page.hasOwnProperty('news')) {
                type = 'news'
              } else if (page.hasOwnProperty('publication')) {
                type = 'publication'
              } else {
                console.warn(`page ${linkObject.staticPage} not found`)
                return children
              }

              relativeUrl = page[type]._i18nCL?.relativeUrl
              if (page.language && page[type].i18n && page[type].i18n.hasOwnProperty(page.language)) {
                relativeUrl = page[type].i18n[page.language].relativeUrl
              }

              // // IT: fallback -> if no italian translation, use english
              // // if the language is italian and the relativeUrl is not present, try to get the english one
              // if (lang === 'it') {
              //   relativeUrl = page[type]._i18nCL?.relativeUrl
              //   if (page[type].i18n && page[type].i18n.hasOwnProperty('en')) {
              //     relativeUrl = page[type].i18n['en'].relativeUrl
              //   }
              // }

              if (this.props.linkText) {
                className = 'link link--internal ' + className
              }

              return (
                <NavLink
                  className={'a-internal ' + className}
                  to={{
                    pathname: relativeUrl,
                  }}
                >
                  {linkIcon}
                  {children}
                </NavLink>
              )
            }}
          </NavigationNodeContext.Consumer>
        )
      } else if (linkObject.hasOwnProperty('document') && typeof this.props.linkObject.document === 'object') {
        let mediaUrl = linkObject.document.media.url
        if (linkObject.document._i18nCL && linkObject.document._i18nCL.media) {
          // use i18 media if present
          mediaUrl = linkObject.document._i18nCL.media.url
        }
        return (
          <a className={'link link--download ' + className} href={mediaUrl} target="_blank" rel="noopener">
            {linkIcon}
            {children}
          </a>
        )
      } else if (linkObject.hasOwnProperty('boxOverlay')) {
        const boxText = geti18nClIfMissing(this.props.linkObject.boxOverlay)
        return <ModToolTip tip={boxText._i18nCL}>{children}</ModToolTip>
      }
    } catch (e) {
      console.warn(e)
    }

    return children
  }
}
