import * as React from 'react'
import ModAnchorTag from './ModAnchorTag'
import ModRichText from './ModRichText'
import ModSvg from './ModSvg'

export default function ModAccordionItem({
  content,
  index,
  active,
  setActive,
  id,
}: {
  content: any
  index: number
  active: number
  id: any
  setActive: any
}) {
  const ref = React.useRef<HTMLDivElement>()

  function toggleItem(index) {
    if (active === index) {
      setActive(0)
    } else {
      setActive(index)
    }
  }

  if (content._i18nCL == null) return null

  return (
    <li className={'accordion__item' + (active === index ? ' is-active' : '')} id={'accordion__item-' + id + '-' + index}>
      <div
        className={'accordion__header'}
        onClick={(e) => {
          toggleItem(index)
        }}
      >
        <h3 className={'accordion__title'}>{content._i18nCL.title}</h3>
        <div className={'accordion__icon'}>
          <ModSvg icon={'plus'} />
        </div>
      </div>
      <div className={'accordion__content'} ref={ref} id={'accordion__content-' + id + '-' + index}>
        <div className="accordion__content-wrapper">
          {content._i18nCL.text && <ModRichText richText={content._i18nCL.text} />}
          {content.link && (
            <ModAnchorTag linkObject={content.link} linkText={true} hasIcon={true}>
              {content._i18nCL.linkText}
            </ModAnchorTag>
          )}
        </div>
      </div>
    </li>
  )
}
