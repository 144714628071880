import * as React from 'react'
import { BlockIFrame } from 'interfaces/Interfaces'
import ModAnimation from '../common/ModAnimation'
import { FormattedMessage } from 'react-intl'

export interface ModBlockVideoProps {
  content: BlockIFrame
}

export default class ModBlockIFrame extends React.Component<ModBlockVideoProps> {
  constructor(props) {
    super(props)
  }

  render() {
    const { content } = this.props
    return (
      <div className="block-iframe">
        <div className="grid align-center">
          <ModAnimation className="cell small-24 large-20 xlarge-18">
            {/* <div className="cookieconsent-optout-marketing">
              <div className="cookieconsent-optout-marketing-custom">
                <p>
                  <a
                    onClick={() => {
                      // @ts-ignore
                      window.Cookiebot.show()
                    }}
                  >
                    <FormattedMessage
                      id="cookiePolicy.acceptMarketing"
                      defaultMessage="Please accept marketing cookies to see this content."
                    />
                  </a>
                </p>
              </div>
            </div> */}
            <div className="block-iframe__iframe">
              <iframe
                // data-cookieconsent="marketing"
                width="100%"
                height={(content.height ?? 600) + 'px'}
                // data-src={content._i18nCL.src}
                src={content._i18nCL.src}
              ></iframe>
            </div>
          </ModAnimation>
        </div>
      </div>
    )
  }
}
