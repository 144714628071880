import * as React from 'react'
import { BlockButtons } from '../../interfaces/Interfaces'
import ModAnchorTag from '../common/ModAnchorTag'
import ModAnimation from '../common/ModAnimation'

export interface ModBlockButtonsProps {
  content: BlockButtons
}

export default class ModBlockButtons extends React.Component<ModBlockButtonsProps, {}> {
  render() {
    const { content } = this.props

    let buttons = content.buttons.map((button, index) => {
      if (button._i18nCL) {
        return (
          <ModAnchorTag key={index} linkObject={button.link} className={'button button--hollow'}>
            {button._i18nCL.text}
          </ModAnchorTag>
        )
      } else {
        return null
      }
    })

    return (
      <div className="block-buttons grid align-center">
        <ModAnimation className="cell small-24 large-20 xlarge-18 text-center">{buttons}</ModAnimation>
      </div>
    )
  }
}
