import * as React from 'react'
import { Expertise, PageType } from 'interfaces/Interfaces'
import ModAnchorTag from './ModAnchorTag'
import ModAnimation from './ModAnimation'
import { createStaticPageLinkObject } from 'common/CmsUtils'
import ModRichTextFragment from './ModRichTextFragment'
import { richTextHasContent } from 'common/Utils'

export interface ModExpertiseTeaserProps {
  content: Expertise
  className?: string
}

export default class ModExpertiseTeaser extends React.Component<ModExpertiseTeaserProps, {}> {
  render() {
    const { content } = this.props

    if (!(content._i18nCL && richTextHasContent(content._i18nCL.title))) {
      return null
    }

    return (
      <ModAnchorTag linkObject={createStaticPageLinkObject(content, PageType.expertise)}>
        <article className="expertise__teaser">
          <h3 className="expertise__teaser__title">
            <ModRichTextFragment richText={content._i18nCL.title} />
          </h3>
        </article>
      </ModAnchorTag>
    )
  }
}
