import * as React from 'react'
import ModAnimation from '../common/ModAnimation'
import { blockIconTextGrid } from 'interfaces/Interfaces'
import ModImgTag from 'components/common/ModImgTag'
import ModAnchorTag from 'components/common/ModAnchorTag'

export interface ModBlockIconTextGridProps {
  content: blockIconTextGrid
}

export default class ModBlockIconTextGrid extends React.Component<ModBlockIconTextGridProps, {}> {
  render() {
    const { content } = this.props

    const gridElements = content.items.map((item, index) => {
      if (item._i18nCL) {
        return (
          <ModAnimation className="block-icon-text-grid__element cell small-24 medium-12 large-6" key={index}>
            <ModAnchorTag className="block-icon-text-grid__element__link" linkObject={item.link}>
              <ModImgTag className="block-icon-text-grid__element__image" imgObject={item.image} width={300} height={300} />
              <p className="block-icon-text-grid__element__title">{item._i18nCL.title}</p>
              <p className="block-icon-text-grid__element__text">{item._i18nCL.text}</p>
            </ModAnchorTag>
          </ModAnimation>
        )
      } else {
        return null
      }
    })

    return (
      <div className="block-icon-text-grid grid align-center">
        <div className="cell small-24 large-20 xlarge-18">
          <div className="grid">{gridElements}</div>
        </div>
      </div>
    )
  }
}
