import * as React from 'react'
import { blockTeam } from '../../interfaces/Interfaces'
import ModLawyersTeaser from '../common/ModLawyersTeaser'
import ModAnimation from '../common/ModAnimation'
import { FormattedMessage } from 'react-intl'
import ModLawyersTeaserImage from 'components/common/ModLawyersTeaserImage'

export interface ModBlockTeamProps {
  content: blockTeam
}

export default function ModBlockTeam(props: ModBlockTeamProps) {
  const ref = React.useRef(null)
  const buttonRef = React.useRef(null)

  const [isWindowSmaller, setIsWindowSmaller] = React.useState(false)
  const [isWindowBigger, setIsWindowBigger] = React.useState(false)

  React.useEffect(() => {
    setIsWindowSmaller(window.innerWidth < 1280)
    setIsWindowBigger(window.innerWidth >= 1920)
  }, [])

  React.useEffect(() => {
    const handleResize = () => {
      setIsWindowSmaller(window.innerWidth < 1280)
      setIsWindowBigger(window.innerWidth >= 1920)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const { content } = props

  const getKeyContacts = () => {
    if (keyContacts.length > 0) {
      return <div className="grid lawyers">{keyContacts}</div>
    } else {
      return null
    }
  }

  const getMoreContacts = () => {
    if (moreContacts && moreContacts.length > 0) {
      return (
        <>
          <div className="grid lawyers hidden-moreContacts" ref={ref}>
            {moreContacts}
          </div>

          <div className="block-buttons grid align-center" ref={buttonRef}>
            <ModAnimation className="cell small-24 large-20 xlarge-18 text-center">
              <div
                className="button button--hollow"
                onClick={() => {
                  buttonRef.current.classList.add('hide-button')
                  ref.current.classList.remove('hidden-moreContacts')
                }}
              >
                <FormattedMessage id="team.more" defaultMessage="More" />
              </div>
            </ModAnimation>
          </div>
        </>
      )
    } else {
      return null
    }
  }

  const keyContacts = content.team
    .filter((item) => !item.member.lawyer?.lawyer?.hideLawyer)
    .map((item, index) => {
      function contentForItem() {
        let memberContent = null
        let title = null
        if (item.member.lawyer?.lawyer) {
          memberContent = <ModLawyersTeaser content={item.member.lawyer?.lawyer} />
          title = item.member.lawyer?._i18nCL?.title
        } else {
          memberContent = (
            <ModAnimation tag="article" className="lawyer-teaser">
              <ModLawyersTeaserImage image={item.member.other?.image} />
              <div className={`lawyer-teaser__content`}>
                {item.member.other?._i18nCL?.position && (
                  <span className={`label lawyer-teaser__content__label`}>{item.member.other?._i18nCL?.position}</span>
                )}
                <h2 className={`lawyer-teaser__content__title`}>{item.member.other?.name}</h2>
              </div>
            </ModAnimation>
          )
          title = item.member.other?._i18nCL?.title
        }

        return (
          <ModAnimation>
            <div className="block-team__lawyer-label">
              <span className="label">
                {title ? title : <FormattedMessage id="expertise.key-contact" defaultMessage="Key Contact" />}
              </span>
            </div>
            {memberContent}
          </ModAnimation>
        )
      }

      return (
        <div
          className={
            'cell small-12 medium-12 large-6 xlarge-4 xxlarge-4 ' +
            (index % 3 === 0 && index % 6 !== 0 ? 'large-offset-4 ' : '') +
            (index % 6 === 0 ? 'large-offset-2 ' : '') +
            (index % 4 === 0 && index % 8 !== 0 ? 'xlarge-offset-4 ' : '') +
            (index % 8 === 0 ? 'xlarge-offset-2 ' : '') +
            (index % 5 === 0 && index % 10 !== 0 ? 'xxlarge-offset-4 ' : '') +
            (index % 10 === 0 ? 'xxlarge-offset-2 ' : '')
          }
          key={index}
        >
          {contentForItem()}
        </div>
      )
    })

  const moreContacts = content?.moreTeamMembers
    ?.filter((item) => !item.member.lawyer?.lawyer?.hideLawyer)
    .map((item, index) => {
      let keyIndex = Math.ceil(keyContacts.length / 4) * 4
      if (isWindowSmaller) keyIndex = Math.ceil(keyContacts.length / 3) * 3
      if (isWindowBigger) keyIndex = Math.ceil(keyContacts.length / 5) * 5

      const newIndex = index + keyIndex

      function contentForItem() {
        let memberContent = null
        let title = null
        if (item.member.lawyer?.lawyer) {
          memberContent = <ModLawyersTeaser content={item.member.lawyer?.lawyer} />
          title = item.member.lawyer?._i18nCL?.title
        } else {
          memberContent = (
            <ModAnimation tag="article" className="lawyer-teaser">
              <ModLawyersTeaserImage image={item.member.other?.image} />
              <div className={`lawyer-teaser__content`}>
                {item.member.other?._i18nCL?.position && (
                  <span className={`label lawyer-teaser__content__label`}>{item.member.other?._i18nCL?.position}</span>
                )}
                <h2 className={`lawyer-teaser__content__title`}>{item.member.other?.name}</h2>
              </div>
            </ModAnimation>
          )
          title = item.member.other?._i18nCL?.title
        }

        return (
          <ModAnimation>
            <div className="block-team__lawyer-label">
              <span className="label">
                {title ? title : <FormattedMessage id="expertise.key-contact" defaultMessage="Key Contact" />}
              </span>
            </div>
            {memberContent}
          </ModAnimation>
        )
      }

      return (
        <div
          className={
            'cell small-12 medium-12 large-6 xlarge-4 xxlarge-4 ' +
            (newIndex % 3 === 0 && newIndex % 6 !== 0 ? 'large-offset-4 ' : '') +
            (newIndex % 6 === 0 ? 'large-offset-2 ' : '') +
            (newIndex % 4 === 0 && newIndex % 8 !== 0 ? 'xlarge-offset-4 ' : '') +
            (newIndex % 8 === 0 ? 'xlarge-offset-2 ' : '') +
            (newIndex % 5 === 0 && newIndex % 10 !== 0 ? 'xxlarge-offset-4 ' : '') +
            (newIndex % 10 === 0 ? 'xxlarge-offset-2 ' : '')
          }
          key={index}
        >
          {contentForItem()}
        </div>
      )
    })

  return (
    <div className="block-team grid align-center">
      <div className="cell small-24">{getKeyContacts()}</div>
      <div className="cell small-24">{getMoreContacts()}</div>
    </div>
  )
}
