import * as React from 'react'
import { Lawyer, PageType } from 'interfaces/Interfaces'
import ModAnchorTag from './ModAnchorTag'
import ModImgTag from './ModImgTag'
import ModAnimation from './ModAnimation'
import { createStaticPageLinkObject } from 'common/CmsUtils'
import { getLayerPositionByOffice } from './Utils'
import ModLawyersTeaserImage from './ModLawyersTeaserImage'

export interface ModLawyersTeaserProps {
  content: Lawyer
  label?: string
  isBlockTeamOffice?: boolean
}

export default class ModLawyersTeaser extends React.Component<ModLawyersTeaserProps, {}> {
  render() {
    const { content, label, isBlockTeamOffice } = this.props

    const positionTitle = getLayerPositionByOffice(content.offices, content.position)

    const image = <ModLawyersTeaserImage image={content.teaserImage} />

    let reactContent = (
      <ModAnchorTag linkObject={createStaticPageLinkObject(content, PageType.lawyer)}>
        <ModAnimation tag="article" className="lawyer-teaser">
          {label && <span className="label">{label}</span>}
          {image}

          <div className={`lawyer-teaser__content ${isBlockTeamOffice ? 'content__small' : ''}`}>
            {positionTitle && (
              <>
                <span className={`label lawyer-teaser__content__label${isBlockTeamOffice ? '__small' : ''}`}>
                  {positionTitle}
                </span>
              </>
            )}
            <h2 className={`lawyer-teaser__content__title${isBlockTeamOffice ? '__small' : ''}`}>
              {content.contact.academicTitle} {content.contact.firstName}{' '}
              <span className="lawyer-teaser__content__title-br">
                <br />
              </span>{' '}
              {content.contact.lastName}
            </h2>
          </div>
        </ModAnimation>
      </ModAnchorTag>
    )

    if (content.hideLawyer) {
      reactContent = (
        <ModAnimation tag="article" className="lawyer-teaser">
          {label && <span className="label">{label}</span>}
          {image}

          <div className={`lawyer-teaser__content ${isBlockTeamOffice ? 'content__small' : ''}`}>
            {positionTitle && (
              <>
                <span className={`label lawyer-teaser__content__label${isBlockTeamOffice ? '__small' : ''}`}>
                  {positionTitle}
                </span>
              </>
            )}
            <h2 className={`lawyer-teaser__content__title${isBlockTeamOffice ? '__small' : ''}`}>
              {content.contact.academicTitle} {content.contact.firstName} {content.contact.lastName}
            </h2>
          </div>
        </ModAnimation>
      )
    }

    return reactContent
  }
}
