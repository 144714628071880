import ModAnimation from "components/common/ModAnimation";
import * as React from "react";
import { BlockExpertiseGrid, PageType } from "../../interfaces/Interfaces";
import ModExpertiseTeaser from "../common/ModExpertiseTeaser";

export interface ModBlockExpertiseGridProps {
  content: BlockExpertiseGrid;
}

export default class ModBlockExpertiseGrid extends React.Component<
  ModBlockExpertiseGridProps,
  {}
> {
  render() {
    const { content } = this.props;

    const expertise = content.expertise.map((item, index) => {
      //TODO: implement slugs. Example shown below
      return (
        <div className="cell small-24 medium-12 large-6" key={index}>
          <ModAnimation>
            <ModExpertiseTeaser content={item} />
          </ModAnimation>
        </div>
      );
    });

    return (
      <div className="block-expertise-grid grid align-center">
        <div className="cell small-24 medium-24 large-22">
          <div className={"grid expertise expertise--" + content.category.toString()}>
            {expertise}
          </div>
        </div>
      </div>
    );
  }
}
