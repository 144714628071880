import * as React from 'react'
import { injectIntl } from 'react-intl'
import moment = require('moment')
import { Publication, BlockNewsPublicationsGridType, BlockHeader } from '../../interfaces/Interfaces'
import RootState from 'vo/RootState'
import ModTemplateBlockList from './ModTemplateBlockList'
import ModBlockHeader from '../blocks/ModBlockHeader'
import ModNewsPublicationsTeaser from '../common/ModNewsPublicationsTeaser'
import ModAuthors from '../common/ModAuthors'
import ModTags from '../common/ModTags'
import { createBlockNewsPublicationsGrid } from 'common/CmsUtils'
import ModBlockNewsPublicationsGrid from 'components/blocks/ModBlockNewsPublicationsGrid'
import ModAnimation from 'components/common/ModAnimation'
import ModRichText from 'components/common/ModRichText'
import { hasRichTextContent } from 'common/Utils'

export interface ModTemplatePublicationProps {
  publication: Publication
  currentLanguage: string
  intl: any
}

class ModTemplatePublication extends React.Component<ModTemplatePublicationProps> {
  rootState

  constructor(props) {
    super(props)
    this.rootState = RootState
  }

  render() {
    const { publication, intl, currentLanguage } = this.props

    const furtherPublications = publication.furtherPublications.map((item, index) => {
      return (
        <div className="cell small-24 medium-12 large-12" key={index}>
          <ModNewsPublicationsTeaser currentLanguage={currentLanguage} content={item} type="news" />
        </div>
      )
    })

    const dateFormat = new Date(publication.date)
    const date = currentLanguage === 'de' ? moment(dateFormat).format('DD. MMMM YYYY') : moment(dateFormat).format('DD MMMM YYYY')
    let category = ''
    let moreLinkText = ''

    switch (publication.category) {
      case 'briefing': {
        category = intl.formatMessage({ id: 'publicationCategory.briefing', defaultMessage: 'Briefing' })
        moreLinkText = intl.formatMessage({ id: 'publicationLinkText.briefings', defaultMessage: 'Briefings' })
        break
      }
      case 'publication': {
        category = intl.formatMessage({ id: 'publicationCategory.publication', defaultMessage: 'Publication' })
        moreLinkText = intl.formatMessage({ id: 'publicationLinkText.publications', defaultMessage: 'Publications' })
        break
      }
      case 'speakingEngagement': {
        category = intl.formatMessage({ id: 'publicationCategory.speakingEngagement', defaultMessage: 'Speaking Engagement' })
        moreLinkText = intl.formatMessage({
          id: 'publicationLinkText.speakingEngagements',
          defaultMessage: 'Speaking Engagements',
        })
        break
      }
      case 'guide': {
        category = intl.formatMessage({ id: 'publicationCategory.guide', defaultMessage: 'Guide' })
        moreLinkText = intl.formatMessage({ id: 'publicationLinkText.guides', defaultMessage: 'Guides' })
        break
      }
      case 'legalInsight': {
        category = intl.formatMessage({ id: 'publicationCategory.legalInsight', defaultMessage: 'Legal Insight' })
        moreLinkText = intl.formatMessage({ id: 'publicationLinkText.legalInsights', defaultMessage: 'Legal Insights' })
      }
      default:
        break
    }

    const publicationHeader: BlockHeader = {
      _id: publication._id,
      internalDescription: publication.internalDescription,
      items: [
        {
          _i18nCL: {
            subtitle: date + ' I ' + category,
            title: publication._i18nCL.title,
          },
          image: publication.image,
        },
      ],
    }

    return (
      <div className="publications">
        <ModBlockHeader content={publicationHeader} />

        {hasRichTextContent(publication._i18nCL.leadText) && (
          <div className="block-lead grid align-center">
            <ModAnimation className="cell small-24 large-20 xlarge-18">
              <ModRichText className="block-lead__text" richText={publication._i18nCL.leadText} />
            </ModAnimation>
          </div>
        )}

        <ModTemplateBlockList currentLanguage={currentLanguage} templateData={publication.content} rootState={this.rootState} />
        <ModTags tags={publication.expertise} />
        <ModAuthors
          mainAuthors={publication.mainAuthors}
          furtherAuthors={publication.furtherAuthors}
          labelType={publication.category}
          removeHiddenLawyerLink={true}
        />
        <ModBlockNewsPublicationsGrid
          currentLanguage={currentLanguage}
          content={createBlockNewsPublicationsGrid(
            BlockNewsPublicationsGridType.publications,
            intl.formatMessage({ id: 'general.further', defaultMessage: 'Further' }) + ' ' + moreLinkText,
            publication.furtherPublications,
            publication.moreLink,
            moreLinkText
          )}
        />
      </div>
    )
  }
}

export default injectIntl(ModTemplatePublication)
