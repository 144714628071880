import moment = require('moment')
import * as React from 'react'
import { News, BlockNewsPublicationsGridType, BlockHeader } from '../../interfaces/Interfaces'
import RootState from 'vo/RootState'
import ModTemplateBlockList from './ModTemplateBlockList'
import ModBlockHeader from '../blocks/ModBlockHeader'
import ModNewsPublicationsTeaser from '../common/ModNewsPublicationsTeaser'
import ModAuthors from '../common/ModAuthors'
import ModTags from '../common/ModTags'
import ModAnimation from 'components/common/ModAnimation'
import { createBlockNewsPublicationsGrid } from 'common/CmsUtils'
import ModBlockNewsPublicationsGrid from 'components/blocks/ModBlockNewsPublicationsGrid'
import ModRichText from 'components/common/ModRichText'
import { injectIntl } from 'react-intl'
import { richTextHasContent, richTextToPlainText } from 'common/Utils'

export interface ModTemplateNewsProps {
  news: News
  currentLanguage: string
  intl: any
}

class ModTemplateNews extends React.Component<ModTemplateNewsProps> {
  rootState

  constructor(props) {
    super(props)
    this.rootState = RootState
  }

  render() {
    const { news, currentLanguage, intl } = this.props

    const furtherNews = news.furtherNews.map((item, index) => {
      return (
        <div className="cell small-24 medium-12 large-12" key={index}>
          <ModNewsPublicationsTeaser currentLanguage={currentLanguage} content={item} type="news" />
        </div>
      )
    })

    const dateFormat = new Date(news.date)
    const date = currentLanguage === 'de' ? moment(dateFormat).format('DD. MMMM YYYY') : moment(dateFormat).format('DD MMMM YYYY')

    let category = ''

    switch (news.category) {
      case 'corporateNews': {
        category = 'Corporate News'
        break
      }
      case 'dealsAndCases': {
        category = intl.formatMessage({ id: 'newsCategory.dealsAndCases', defaultMessage: 'Deals & Cases' })
        break
      }
      case 'event': {
        category = 'Event'
        break
      }
      default:
        break
    }

    const moreDealCasesTitle =
      news.expertise.length > 0 && richTextHasContent(news.expertise[0]._i18nCL.title)
        ? intl.formatMessage({ id: 'news.furtherDealsRegarding', defaultMessage: 'Further Deals, Cases & News regarding' }) +
          ' ' +
          richTextToPlainText(news.expertise[0]._i18nCL.title)
        : intl.formatMessage({ id: 'news.furtherDeals', defaultMessage: 'Further Deals, Cases & News' })

    const newsHeader: BlockHeader = {
      _id: news._id,
      internalDescription: news.internalDescription,
      items: [
        {
          _i18nCL: {
            subtitle: date + ' I ' + category,
            title: news._i18nCL.title,
          },
          image: news.image,
        },
      ],
    }

    return (
      <div className="news">
        <ModBlockHeader content={newsHeader} />

        <div className="block-lead grid align-center">
          <ModAnimation className="cell small-24 large-20 xlarge-18">
            {news._i18nCL.leadText && <ModRichText className="block-lead__text" richText={news._i18nCL.leadText} />}
          </ModAnimation>
        </div>

        {/* TODO: Marvin check? */}
        <ModTemplateBlockList currentLanguage={currentLanguage} templateData={news.content} rootState={this.rootState} />
        <ModTags tags={news.expertise} />
        <ModAuthors mainAuthors={news.mainAuthors} furtherAuthors={news.furtherAuthors} labelType={news.category} />
        <ModBlockNewsPublicationsGrid
          currentLanguage={currentLanguage}
          content={createBlockNewsPublicationsGrid(
            BlockNewsPublicationsGridType.news,
            moreDealCasesTitle,
            news.furtherNews,
            news.moreLink,
            'Deals, Cases & News'
          )}
        />
      </div>
    )
  }
}

export default injectIntl(ModTemplateNews)
