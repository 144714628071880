import * as React from 'react'
import { injectIntl } from 'react-intl'

export interface ModPrintViewProps {
  className?: string
  children: React.ReactNode
}

const ModPrintView = (props: ModPrintViewProps) => {
  const className =  props.className || ''


 const invalidateChildren = (children) => {
    if (typeof children === 'string') {
      return children
    }
    else if (typeof children === 'undefined') {
      return null
    }
    return children
  }


  let children = invalidateChildren(props.children)

  return (
    <div className={'print-view' + className} >
      <div className="print-view__head">
        {/* Hier könnte ein Fixer Header setehen */}
      </div>
      <div className="print-view__footer">
        {/* Hier könnte ein Fixer Footer setehen */}
      </div>
      <table className={'print-view__table'}>
        <thead>
          <tr>
            <td>
              <div className="print-view__head-spacer"></div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {children}
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>
              <div className="print-view__footer-spacer"></div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default injectIntl(ModPrintView)