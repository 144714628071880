import * as React from 'react'

import { Expertise, BlockNewsPublicationsGridType, BlockHeader, RichTextBackground } from '../../interfaces/Interfaces'
import ModBlockLead from '../blocks/ModBlockLead'
import ModBlockHeader from '../blocks/ModBlockHeader'
import ModBlockQuote from '../blocks/ModBlockQuote'
import ModSvg from '../common/ModSvg'
import ModAnimation from '../common/ModAnimation'
import RootState from 'vo/RootState'
import { createBlockNewsPublicationsGrid, stringToBlockTitleObject } from 'common/CmsUtils'
import ModBlockRichText from 'components/blocks/ModBlockRichText'
import ModBlockNewsPublicationsGrid from 'components/blocks/ModBlockNewsPublicationsGrid'
import ModPrintView from 'components/common/ModPrintView'
import { FormattedMessage, injectIntl } from 'react-intl'
import ModBlockButtons from 'components/blocks/ModBlockButtons'
import ModBlockTitle from 'components/blocks/ModBlockTitle'
import ModLawyersGrid from 'components/common/ModLawyersGrid'
import { richTextToPlainText } from 'common/Utils'
import ModRichTextFragment from 'components/common/ModRichTextFragment'

export interface ModTemplateExpertiseState {}

export interface ModTemplateExpertiseProps {
  expertise: Expertise
  currentLanguage: string
  intl: any
}

class ModTemplateExpertise extends React.Component<ModTemplateExpertiseProps, ModTemplateExpertiseState> {
  rootState

  constructor(props) {
    super(props)
    this.state = {}

    this.rootState = RootState
  }

  componentDidMount() {
    const { ScrollToPlugin } = require('gsap/all')
  }

  render() {
    const { expertise, currentLanguage, intl } = this.props
    const plainTitle = richTextToPlainText(expertise._i18nCL?.title)
    const pdfFileName = `${plainTitle.toLocaleLowerCase().replace('&', '-').replace(/ /g, '_')}.pdf`

    const keyContactItems = expertise.keyContacts.map((content, index) => {
      if (content.keyContact) {
        return {
          content: content.keyContact.lawyer,
          label: intl.formatMessage({ id: 'expertise.key-contact', defaultMessage: 'Key Contact' }),
        }
      } else if (content.topicContact) {
        return {
          content: content.topicContact.contact,
          label:
            content.topicContact._i18nCL && content.topicContact?._i18nCL.topic
              ? content.topicContact?._i18nCL?.topic
              : 'Topic Contact',
        }
      }
    })

    const teamLeaderItems = expertise.teamLeaders.map((content, index) => {
      return {
        content: content.lawyer,
        label: content._i18nCL?.topic,
      }
    })

    const teamMemberItems = expertise.teamMembers.map((content, index) => {
      return {
        content: content.lawyer,
        label: content._i18nCL?.topic,
      }
    })

    const keyContactBlock = expertise.keyContacts.length > 0 && (
      <div className="lawyers-wrapper">
        <br />
        <ModBlockTitle
          key="titleblock-keycontacts"
          content={stringToBlockTitleObject(
            expertise._i18nCL.keyContactsTitle && expertise._i18nCL.keyContactsTitle != ''
              ? expertise._i18nCL.keyContactsTitle
              : 'Main Contacts'
          )}
        />
        <ModLawyersGrid key="test1" content={keyContactItems} />
      </div>
    )

    const teamLeadersBlock = expertise.teamLeaders.length > 0 && (
      <div className="lawyers-wrapper">
        <br />
        <ModBlockTitle
          key="titleblock-teamleaders"
          content={stringToBlockTitleObject(
            expertise._i18nCL.teamLeadersTitle != '' ? expertise._i18nCL.teamLeadersTitle : 'Team Leaders'
          )}
        />
        <ModLawyersGrid key="test1" content={teamLeaderItems} />
      </div>
    )

    const teamMembersBlock = expertise.teamMembers.length > 0 && (
      <div className="lawyers-wrapper">
        <br />
        <ModBlockTitle
          key="titleblock-teammembers"
          content={stringToBlockTitleObject(
            expertise._i18nCL.teamMembersTitle != '' ? expertise._i18nCL.teamMembersTitle : 'Team Members'
          )}
        />
        <ModLawyersGrid key="test1" content={teamMemberItems} />
      </div>
    )

    const descriptionRichText = {
      _i18nCL: {
        text: expertise._i18nCL.description,
      },
      background: RichTextBackground.white,
    }

    const expertiseHeader: BlockHeader = {
      _id: expertise._id,
      internalDescription: expertise.internalDescription,
      items: [
        {
          _i18nCL: {
            subtitle: plainTitle,
            title: expertise._i18nCL.headerTitle,
          },
        },
      ],
    }

    const hero = <ModBlockHeader content={expertiseHeader} />

    const heroPrint = (
      <div className={'hero-print'}>
        <div className={'hero-print__content'}>
          <div>
            <span className={'hero-print__subtitle label'}>{plainTitle}</span>
            <h1 className={'heor-print__title'}>
              <ModRichTextFragment richText={expertise._i18nCL.headerTitle} />
            </h1>
          </div>
        </div>
      </div>
    )

    const content = (
      <React.Fragment>
        <ModBlockLead
          content={{
            _i18nCL: {
              leadText: expertise._i18nCL.leadText,
            },
          }}
        />
        <ModBlockRichText content={descriptionRichText} />

        <div className="block-download small-gap grid align-center">
          <ModAnimation className="cell small-24 large-20 xlarge-18">
            <a className="link link--download" href={`/api/pdf?filename=${pdfFileName}&url=${expertise._i18nCL.relativeUrl}`}>
              <ModSvg icon="url" />
              <FormattedMessage id="expertise.download" defaultMessage="Download One Pager [PDF]" />
            </a>
          </ModAnimation>
        </div>

        {expertise.richText && <ModBlockRichText content={expertise.richText} />}

        {expertise.buttons && <ModBlockButtons content={expertise.buttons} />}

        {expertise.quote && <ModBlockQuote content={expertise.quote} />}

        {keyContactBlock}
        {teamLeadersBlock}
        {teamMembersBlock}

        {expertise.ranking && <ModBlockQuote content={expertise.ranking} />}

        <ModBlockNewsPublicationsGrid
          currentLanguage={currentLanguage}
          content={createBlockNewsPublicationsGrid(
            BlockNewsPublicationsGridType.news,
            intl.formatMessage({ id: 'expertise.dealsRegarding', defaultMessage: 'Deals, Cases & News regarding' }) +
              ' ' +
              plainTitle,
            expertise.news,
            expertise.newsLink
          )}
        />
        <ModBlockNewsPublicationsGrid
          currentLanguage={currentLanguage}
          content={createBlockNewsPublicationsGrid(
            BlockNewsPublicationsGridType.publications,

            intl.formatMessage({ id: 'expertise.latestPublicationsRegarding', defaultMessage: 'Latest Publications regarding' }) +
              ' ' +
              plainTitle,
            expertise.publications,
            expertise.publicationsLink
          )}
        />
      </React.Fragment>
    )

    return (
      <div className="expertise">
        <div className={'expertise__content'}>
          {hero}
          {content}
        </div>
        <ModPrintView
          children={
            <React.Fragment>
              {heroPrint}
              {content}
            </React.Fragment>
          }
        ></ModPrintView>
      </div>
    )
  }
}

export default injectIntl(ModTemplateExpertise)
