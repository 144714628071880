import * as React from 'react'
import { createReactComponents } from 'common/DraftJsToReact'
import { RichText } from '../../interfaces/Interfaces'

export interface ModRichTextProps {
  richText: RichText
  className?: string
}

export default class ModRichText extends React.Component<ModRichTextProps, {}> {
  constructor(props) {
    super(props)
  }

  render() {
    const { richText, className } = this.props

    return createReactComponents(richText, className ? className : '')
  }
}
