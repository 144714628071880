import * as React from 'react'

export interface ModToolTipProps {
  tip: string
  children: React.ReactChild
}

export default function ModToolTip({ tip, children }: ModToolTipProps) {
  const [visiable, setVisible] = React.useState(false)
  const [className, setClasName] = React.useState('')
  const myRef = React.useRef(undefined)

  const accordionVisible = (visible: boolean) => {
    const activeItems = document.querySelectorAll('.accordion__item.is-active')
    activeItems.forEach((activeItem) => {
      const subElement = activeItem.querySelector('.accordion__content')
      if (subElement instanceof HTMLElement) {
        subElement.style.overflow = visible ? 'visible' : 'hidden'
      }
    })
  }

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setVisible(false)
      accordionVisible(false)
    } else {
      accordionVisible(true)
    }
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  })

  return (
    <>
      <a
        ref={myRef}
        onClick={(e) => {
          e.preventDefault()

          if (!visiable) {
            const offset = e.clientX
            const width = window.innerWidth

            let className = ''
            if (offset - 130 <= 0 || offset + 150 >= width) {
              if (offset - 130 <= 0) {
                className = 'setRight'
              } else {
                className = 'setLeft'
              }
            }

            setClasName(className)
            setVisible(true)
            accordionVisible(true)
          }
        }}
        data-text={tip}
        className={visiable ? `tooltip tooltip-visible ${className}` : 'tooltip'}
      >
        {children}
      </a>
    </>
  )
}
