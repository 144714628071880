import * as React from 'react'
import { blockTeamOffice } from '../../interfaces/Interfaces'
import ModLawyersTeaser from '../common/ModLawyersTeaser'
import ModAnimation from '../common/ModAnimation'

export interface ModBlockTeamProps {
  content: blockTeamOffice
}

export default class ModBlockTeamOffice extends React.Component<ModBlockTeamProps, {}> {
  render() {
    const { content } = this.props

    const team = content.teamLead.concat(content.team)

    const getKeyContacts = () => {
      if (keyContacts.length > 0) {
        return <div className="grid align-bottom">{keyContacts}</div>
      } else {
        return null
      }
    }

    const keyContacts = team
      .filter((item) => !item.lawyer.hideLawyer)
      .map((item, index) => {
        function contentForItem() {
          return (
            <ModAnimation>
              {item?._i18nCL?.title && <span className="label __small">{item?._i18nCL?.title}</span>}
              <ModLawyersTeaser content={item.lawyer} isBlockTeamOffice={true} />
            </ModAnimation>
          )
        }

        return (
          <div
            className={
              'cell small-24 medium-12 large-8 xlarge-6 ' +
              (index % 3 === 0 && index % 6 !== 0 ? 'xlarge-offset-5' : '') +
              (index % 6 === 0 ? 'xlarge-offset-3' : '')
            }
            key={index}
          >
            {contentForItem()}
          </div>
        )
      })

    return (
      <div className="block-team grid align-center">
        <div className="cell small-14 large-16 xlarge-16">{getKeyContacts()}</div>
      </div>
    )
  }
}
