import * as React from 'react'
import { TweenMax, TimelineMax, Power0 } from 'gsap'
import { MainAuthors, Lawyer, PageType } from 'interfaces/Interfaces'
import ModLawyersTeaser from '../common/ModLawyersTeaser'
import ModAnchorTag from '../common/ModAnchorTag'
import ModSvg from '../common/ModSvg'
import { createStaticPageLinkObject } from 'common/CmsUtils'
import ModAnimation from './ModAnimation'
import { FormattedMessage } from 'react-intl'
import ModImgTag from './ModImgTag'

export interface ModAuthorsState {}

export interface ModAuthorsProps {
  mainAuthors: MainAuthors[]
  furtherAuthors: Lawyer[]
  labelType?: string
  removeHiddenLawyerLink?: boolean
}

export default class ModAuthors extends React.Component<ModAuthorsProps, ModAuthorsState, {}> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { ScrollToPlugin } = require('gsap/all')
  }

  render() {
    const { mainAuthors, furtherAuthors, labelType, removeHiddenLawyerLink } = this.props
    let authorLength = mainAuthors.length + furtherAuthors.length
    let getLabel = null
    let getExternalLabel = null

    switch (labelType) {
      case 'publication':
      case 'guide':
      case 'briefing':
      case 'legalInsight': {
        getLabel =
          authorLength > 1 ? (
            <FormattedMessage id="author.authors" defaultMessage="Authors" />
          ) : (
            <FormattedMessage id="author.author" defaultMessage="Author" />
          )
        getExternalLabel = <FormattedMessage id="author.externalAuthor" defaultMessage="External Author" />
        break
      }
      case 'speakingEngagement': {
        getLabel =
          authorLength > 1 ? (
            <FormattedMessage id="author.speakers" defaultMessage="Speakers" />
          ) : (
            <FormattedMessage id="author.speaker" defaultMessage="Speaker" />
          )
        getExternalLabel = <FormattedMessage id="author.externalSpeaker" defaultMessage="External Speaker" />
        break
      }

      case 'event':
      case 'corporateNews': {
        getLabel =
          authorLength > 1 ? (
            <FormattedMessage id="author.lawyers-involved" defaultMessage="Lawyers involved" />
          ) : (
            <FormattedMessage id="author.lawyer-involved" defaultMessage="Lawyer involved" />
          )
        getExternalLabel = <FormattedMessage id="author.externalLawyer" defaultMessage="External Lawyer" />
        break
      }
      case 'dealsAndCases': {
        getLabel = <FormattedMessage id="author.team-involved" defaultMessage="Team involved" />
        getExternalLabel = <FormattedMessage id="author.externalLawyer" defaultMessage="External Lawyer" />
        break
      }
      default:
        break
    }

    if (mainAuthors.length === 0 && furtherAuthors.length === 0) {
      return null
    }

    const getMainAuthors = mainAuthors.map((item, index) => {
      function contentForItem() {
        const key = Object.keys(item)[0] as keyof typeof item

        switch (key) {
          case 'internal': {
            const value = item[key]
            return <ModLawyersTeaser content={value} />
          }

          case 'external': {
            const value = item[key]
            let imageContent = value.image ? (
              <ModImgTag className="lawyer-teaser__image" imgObject={value.image} height={960} width={960} />
            ) : (
              <figure className="lawyer-teaser__image">
                <img src={require('static/img/_blank_square.gif')} alt="placeholder" />
              </figure>
            )

            return (
              <ModAnimation tag="article" className={'lawyer-teaser' + (value.image ? '' : ' plain')}>
                {imageContent}
                <div className={'lawyer-teaser__content' + (value.image ? '' : ' black')}>
                  <span className={'label lawyer-teaser__content__label' + (value.image ? '' : ' black')}>
                    {getExternalLabel}
                  </span>
                  <h2 className={'lawyer-teaser__content__title'}>{value.name}</h2>
                </div>
              </ModAnimation>
            )
          }
        }
      }

      return (
        <div
          className={
            'cell small-12 medium-12 large-8 xlarge-6 ' +
            (index % 3 === 0 && index % 6 !== 0 ? 'xlarge-offset-5' : '') +
            (index % 6 === 0 ? 'xlarge-offset-3' : '')
          }
          key={index}
        >
          {contentForItem()}
        </div>
      )
    })

    const getPosition = (item) => {
      if (item.position._i18nCL.title) {
        return <span className="label">{item.position._i18nCL.title}</span>
      } else {
        return null
      }
    }

    const getFurtherAuthors = furtherAuthors.map((item, index) => {
      let content = (
        <ModAnchorTag linkObject={createStaticPageLinkObject(item, PageType.lawyer)}>
          <h4>
            &#x25AA; &nbsp; {item.contact.firstName} {item.contact.lastName}
          </h4>
        </ModAnchorTag>
      )

      if (removeHiddenLawyerLink) {
        content = (
          <h4>
            &#x25AA; &nbsp; {item.contact.firstName} {item.contact.lastName}
          </h4>
        )
      }

      return (
        <ModAnimation tag={'li'} className="cell small-24 medium-12 large-8" key={index}>
          {content}
        </ModAnimation>
      )
    })

    return (
      <div className="authors  align-center">
        <div className={'grid'}>
          <div className={'cell small-24 medium-12 large-8 xlarge-6 xlarge-offset-3'}>
            <ModAnimation>
              <span className="label">{getLabel}</span>
            </ModAnimation>
          </div>
        </div>

        <div className="grid authors__main">{getMainAuthors}</div>

        {getFurtherAuthors.length > 0 && (
          <div className={'grid'}>
            <div className={'cell small-24 medium-24 large-18 xlarge-18 xlarge-offset-3'}>
              <ul className="grid more-lawyers">{getFurtherAuthors}</ul>
            </div>
          </div>
        )}
      </div>
    )
  }
}
