import * as React from 'react'
import ModAnchorTag from './ModAnchorTag'
import { Expertise, PageType } from 'interfaces/Interfaces'
import { createStaticPageLinkObject } from 'common/CmsUtils'
import ModAnimation from '../common/ModAnimation'
import { richTextHasContent, richTextToPlainText } from 'common/Utils'

export interface ModTagsProps {
  tags: Expertise[]
}

export default class ModTag extends React.Component<ModTagsProps, {}> {
  render() {
    const { tags } = this.props

    if (tags.length === 0) {
      return null
    }

    const getTags = tags.map((item, index) => {
      if (item._i18nCL && richTextHasContent(item._i18nCL.title)) {
        const plainTitle = richTextToPlainText(item._i18nCL.title)
        return (
          <span className="tags__tag" key={index}>
            <ModAnchorTag linkObject={createStaticPageLinkObject(item, PageType.expertise)}>{plainTitle}</ModAnchorTag>
          </span>
        )
      } else {
        return null
      }
    })

    return (
      <div className="tags grid align-center">
        <ModAnimation className="cell small-24 large-20 xlarge-18">
          <span className="label">Tag{tags.length > 1 ? 's' : ''}</span>
          {getTags}
        </ModAnimation>
      </div>
    )
  }
}
