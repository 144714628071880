import * as React from 'react'

export interface ModSvgProps {
  icon: string
}

export function UrlIcon() {
  return (
    <svg className="url" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 988.17 988.17">
      <path
        className="url__top"
        data-name="Pfad 33"
        d="M408.39,286.75l60.34,60.34,220.5-219.52L872.34,310.69,652.83,531.19l60.33,60.33L994,310.69,689.23,5.92Z"
        transform="translate(-5.83 -5.92)"
      />
      <path
        className="url__bottom"
        data-name="Pfad 34"
        d="M127.56,689.31l219.51-220.5-60.4-60.33L5.83,689.31,310.6,994.08,591.44,713.25l-60.33-60.34L310.6,872.43Z"
        transform="translate(-5.83 -5.92)"
      />
      <rect
        data-name="Rechteck 24"
        x="261.71"
        y="457.41"
        width="476.41"
        height="85.33"
        transform="translate(-213.02 494.04) rotate(-45)"
      />
    </svg>
  )
}

export function DownloadIcon() {
  return (
    <svg
      className="downlaod"
      height="100px"
      width="100px"
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 993.73 844.35"
    >
      <path
        className="download__arrow"
        data-name="Pfad 22"
        d="M360.6,77.83H639.3v273.5H815.38L500,643.6,184.58,351.33H360.66Z"
        transform="translate(-3.05 -77.83)"
      />
      <path data-name="Pfad 23" d="M3.05,922.17H996.78V796.3H3.05Z" transform="translate(-3.05 -77.83)" />
    </svg>
  )
}

export function PlayIcon() {
  return (
    <svg height="100px" width="100px" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 32 32" x="0px" y="0px">
      <path d="M3,0.25V31.71L30.25,16ZM5,3.71L26.25,16,5,28.24V3.71Z"></path>
    </svg>
  )
}

export function CloseIcon() {
  return (
    <svg height="100px" width="100px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" version="1.1" x="0px" y="0px">
      <polygon points="91 0 48 43 5 0 0 5 43 48.001 0 91 5 96 48 53 91 96 96 91 53 48.001 96 5"></polygon>
    </svg>
  )
}

export function BoldCloseIcon() {
  return (
    <svg height="100px" width="100px" viewBox="0 0 100 100">
      <path d="M89.9,72.2L67,49.3l22.9-22.9c2.3-2.3,2.3-6.1,0-8.5L77.2,5.1L50,32.3L22.8,5.1L10.1,17.9c-2.3,2.3-2.3,6.1,0,8.5L33,49.3  L10.1,72.2c-2.3,2.3-2.3,6.1,0,8.5l12.7,12.7L50,66.3l27.2,27.2l12.7-12.7C92.3,78.4,92.3,74.6,89.9,72.2z"></path>
    </svg>
  )
}

export function PlusIcon() {
  return (
    <svg height="100px" width="100px" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.76 97.76">
      <polygon points="54.78 43.09 54.78 0 43.09 0 43.09 43.09 0 43.09 0 54.78 43.09 54.78 43.09 97.76 54.78 97.76 54.78 54.78 97.76 54.78 97.76 43.09 54.78 43.09" />
    </svg>
  )
}

export function MinusIcon() {
  return (
    <svg height="100px" width="100px" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.76 11.69">
      <polygon points="54.78 0 54.78 0 43.09 0 43.09 0 0 0 0 11.69 43.09 11.69 43.09 11.69 54.78 11.69 54.78 11.69 97.76 11.69 97.76 0 54.78 0" />
    </svg>
  )
}

export function ArrowDownIcon() {
  return (
    <svg className="icon-arrow-down" height="100px" width="100px" viewBox="0 0 100 100">
      <polygon points="2.5,32.8 15.6,19.7 50,54.2 84.4,19.7 97.5,32.8 50,80.3" />
    </svg>
  )
}

export function ArrowUpIcon() {
  return (
    <svg className="icon-arrow-down" height="100px" width="100px" viewBox="0 0 100 100" transform="rotate(180)">
      <polygon points="2.5,32.8 15.6,19.7 50,54.2 84.4,19.7 97.5,32.8 50,80.3" />
    </svg>
  )
}

export function SearchIcon() {
  return (
    <svg version="1.1" id="Ebene_1" x="0px" y="0px" viewBox="0 0 25 25">
      <path
        d="M22.4,23.5c-0.3,0-0.6-0.1-0.8-0.3L15.5,17c-1.5,1.2-3.3,1.8-5.3,1.8c-4.8,0-8.6-3.9-8.6-8.6s3.9-8.6,8.6-8.6
				s8.6,3.9,8.6,8.6c0,1.9-0.6,3.8-1.8,5.3l6.1,6.2c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8S22.6,23.5,22.4,23.5z M10.2,3.7
				c-3.6,0-6.5,2.9-6.5,6.5s2.9,6.5,6.5,6.5c1.7,0,3.3-0.7,4.6-1.9c0,0,0,0,0,0c1.2-1.2,1.9-2.8,1.9-4.6C16.6,6.6,13.7,3.7,10.2,3.7
				z"
      />
    </svg>
  )
}

export function MailIcon() {
  return (
    <svg height="100px" width="100px" version="1.1" x="0px" y="0px" viewBox="0 0 100 100">
      <path d="M5273.1,2400.1v-2c0-2.8-5-4-9.7-4s-9.7,1.3-9.7,4v2c0,1.8,0.7,3.6,2,4.9l5,4.9c0.3,0.3,0.4,0.6,0.4,1v6.4c0,0.4,0.2,0.7,0.6,0.8l2.9,0.9c0.5,0.1,1-0.2,1-0.8v-7.2c0-0.4,0.2-0.7,0.4-1l5.1-5C5272.4,2403.7,5273.1,2401.9,5273.1,2400.1zM5263.4,2400c-4.8,0-7.4-1.3-7.5-1.8v0c0.1-0.5,2.7-1.8,7.5-1.8c4.8,0,7.3,1.3,7.5,1.8C5270.7,2398.7,5268.2,2400,5263.4,2400z"></path>
      <path d="M5268.4,2410.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H5268.4z"></path>
      <path d="M5272.7,2413.7h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2414.1,5273.3,2413.7,5272.7,2413.7z"></path>
      <path d="M5272.7,2417h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2417.5,5273.3,2417,5272.7,2417z"></path>
      <path d="M2.5,16.7v66.7h95V16.7H2.5z M83,24.5L50,47L17,24.5H83z M10.3,75.5V29.4l39.7,27l39.7-27v46.1H10.3z"></path>
    </svg>
  )
}

export function LinkedInIcon() {
  return (
    <svg version="1.1" x="0px" y="0px" width="430.117px" height="430.117px" viewBox="0 0 430.117 430.117">
      <path
        id="LinkedIn"
        d="M430.117,261.543V420.56h-92.188V272.193c0-37.271-13.334-62.707-46.703-62.707
        c-25.473,0-40.632,17.142-47.301,33.724c-2.432,5.928-3.058,14.179-3.058,22.477V420.56h-92.219c0,0,1.242-251.285,0-277.32h92.21
        v39.309c-0.187,0.294-0.43,0.611-0.606,0.896h0.606v-0.896c12.251-18.869,34.13-45.824,83.102-45.824
        C384.633,136.724,430.117,176.361,430.117,261.543z M52.183,9.558C20.635,9.558,0,30.251,0,57.463
        c0,26.619,20.038,47.94,50.959,47.94h0.616c32.159,0,52.159-21.317,52.159-47.94C103.128,30.251,83.734,9.558,52.183,9.558z
        M5.477,420.56h92.184v-277.32H5.477V420.56z"
      />
    </svg>
  )
}

export function LocationIcon() {
  return (
    <svg height="100px" width="100px" fill="#000000" viewBox="0 0 24 24" version="1.1" x="0px" y="0px">
      <path
        d="M20.2608696,9.6 C20.2608696,16 12.1304348,23 12.1304348,23 C12.1304348,23 4,16 4,9.6 C4,4.9 7.63478261,1 12.1304348,1 C16.626087,1 20.2608696,4.9 20.2608696,9.6 Z M12.1304348,12.9565217 C14.2435243,12.9565217 15.9565217,11.2435243 15.9565217,9.13043478 C15.9565217,7.01734531 14.2435243,5.30434783 12.1304348,5.30434783 C10.0173453,5.30434783 8.30434783,7.01734531 8.30434783,9.13043478 C8.30434783,11.2435243 10.0173453,12.9565217 12.1304348,12.9565217 Z"
        fill="#000000"
      ></path>
    </svg>
  )
}

export function FilterIcon() {
  return (
    <svg data-name="Ebene 1" viewBox="0 0 88 88">
      <path
        d="M8,6A2,2,0,0,0,6.38,9.18L39,53.79V77.62a2,2,0,0,0,.75,1.56L57.77,93.56A2,2,0,0,0,61,92V53.79L93.62,9.18A2,2,0,0,0,92,6Z"
        transform="translate(-6 -6)"
      />
    </svg>
  )
}

export function ArrowRight() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.31 50">
      <polygon points="18.15 24.97 0 43.89 6.37 50 30.31 24.97 6.37 0 0 6.11 18.15 24.97" />
    </svg>
  )
}

export function ArrowLeft() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.31 50">
      <polygon points="12.16 25.03 30.31 6.11 23.94 0 0 25.03 23.94 50 30.31 43.89 12.16 25.03" />
    </svg>
  )
}

export default class ModSvg extends React.Component<ModSvgProps, {}> {
  render() {
    const { icon } = this.props

    switch (icon) {
      case 'url':
        return <UrlIcon />

      case 'download':
        return <DownloadIcon />

      case 'play':
        return <PlayIcon />

      case 'close':
        return <CloseIcon />

      case 'bold-close':
        return <BoldCloseIcon />

      case 'plus':
        return <PlusIcon />

      case 'minus':
        return <MinusIcon />

      case 'arrow-down':
        return <ArrowDownIcon />

      case 'arrow-up':
        return <ArrowUpIcon />

      case 'search':
        return <SearchIcon />

      case 'mail':
        return <MailIcon />

      case 'linkedin':
        return <LinkedInIcon />

      case 'location':
        return <LocationIcon />

      case 'filter':
        return <FilterIcon />

      case 'arrow-right':
        return <ArrowRight />

      case 'arrow-left':
        return <ArrowLeft />

      default:
        null
    }
  }
}
