import pino from 'pino'
import type { LoggerOptions } from 'pino'

// Special JSON fields: https://cloud.google.com/logging/docs/structured-logging#structured_logging_special_fields
// Pino API doc: https://github.com/pinojs/pino/blob/master/docs/api.md
function getLoggerOptions(): LoggerOptions {
  if (process.env.NODE_ENV === 'production') {
    return {
      name: 'baerkarrer-website',
      level: 'debug',
      messageKey: 'message', // The message that appears on the log entry line in the Logs Explorer.
      formatters: {
        level: (label) => {
          return {
            severity: severity(label),
          }
        },
      },
    }
  } else {
    return {
      transport: {
        target: 'pino-pretty',
      },
      name: 'baerkarrer-website',
      level: 'trace',
    }
  }
}

// LogSeverity List: https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry#LogSeverity
function severity(label: string) {
  switch (label) {
    case 'trace':
      return 'DEBUG'
    case 'debug':
      return 'DEBUG'
    case 'info':
      return 'INFO'
    case 'warn':
      return 'WARNING'
    case 'error':
      return 'ERROR'
    case 'fatal':
      return 'CRITICAL'
    default:
      return 'DEFAULT'
  }
}

export const logger = pino(getLoggerOptions())
