import { Domain, IPublicSettings, IStateController, IPage, Website } from '../interfaces/Interfaces'

export enum LoadingState {
  idle = 'idle',
  loading = 'loading',
  offline = 'offline',
  reload = 'reload',
}

export default class implements IStateController {
  pages: {
    [type: string]: {
      [key: string]: IPage
    }
  }
  currentPage?: {
    type: string
    id: string
  }
  websiteSettings: Website
  domain: Domain
  iFrameUrls: string[]
  cookies: { cookie_consent_dismissed: boolean }
  editMode: boolean
  publicConfig: IPublicSettings
  loadingState: LoadingState
  displaySize: string

  constructor() {
    this.pages = {}
    this.websiteSettings = null
    this.publicConfig = null
    this.domain = null
    this.iFrameUrls = []
    this.cookies = {
      cookie_consent_dismissed: false,
    }
    this.editMode = false
    this.loadingState = LoadingState.idle
  }
}
