import * as React from 'react'
import { ExpertiseI18n, TemplateOffice } from '../../interfaces/Interfaces'
import ModBlockLead from 'components/blocks/ModBlockLead'
import ModAnchorTag from 'components/common/ModAnchorTag'
import ModAnimation from 'components/common/ModAnimation'
import ModExpertiseTeaser from 'components/common/ModExpertiseTeaser'
import { FormattedMessage } from 'react-intl'
import ModTemplateBlockList from './ModTemplateBlockList'
import RootState from 'vo/RootState'
import { richTextHasContent, richTextToPlainText } from 'common/Utils'
import { geti18nClIfMissing } from 'components/common/Utils'
import ModSvg from 'components/common/ModSvg'

export interface ModTemplateOfficeProps {
  content: TemplateOffice
  currentLanguage: string
  rootState: RootState
}

export default class ModTemplateOffice extends React.Component<ModTemplateOfficeProps> {
  sendMail(email: string) {
    window.location.href = 'mailto:' + email
  }

  render() {
    const { content, currentLanguage, rootState } = this.props
    const plainTitle = richTextToPlainText(content.office._i18nCL?.title)

    const getPractiseAreas = () => {
      if (content.practices.length > 0) {
        return (
          <div className="grid office__expertise align-center">
            <div className="cell small-24 large-20 xlarge-18">
              <ModAnimation>
                <span className="label">
                  <FormattedMessage id="global.practice-areas" defaultMessage="Practice Areas" />
                </span>
              </ModAnimation>
              <div className={'grid expertise expertise--practices'}>{practiseAreas}</div>
            </div>
          </div>
        )
      } else {
        return null
      }
    }

    const practiseAreas = content.practices.map((item, index) => {
      item = geti18nClIfMissing(item)

      if (item._i18nCL && richTextHasContent(item._i18nCL.title)) {
        return (
          <ModAnimation className="cell small-24 medium-12 large-8" key={index}>
            <ModExpertiseTeaser content={item} />
          </ModAnimation>
        )
      } else {
        return null
      }
    })

    const getSectors = () => {
      if (content.sectors.length > 0) {
        return (
          <div className="small-gap grid office__expertise align-center">
            <div className="cell small-24 large-20 xlarge-18">
              <ModAnimation>
                <span className="label">
                  <FormattedMessage id="global.sectors" defaultMessage="Sectors" />
                </span>
              </ModAnimation>
              <div className={'grid expertise expertise--sector'}>{sectors}</div>
            </div>
          </div>
        )
      } else {
        return null
      }
    }

    const sectors = content.sectors.map((item, index) => {
      if (item._i18nCL && richTextHasContent(item._i18nCL.title)) {
        return (
          <ModAnimation className="cell small-24 medium-12 large-8" key={index}>
            <ModExpertiseTeaser content={item} />
          </ModAnimation>
        )
      } else {
        return null
      }
    })

    const getContactInfo = () => {
      if (content.office._i18nCL) {
        return (
          <div className="grid align-center office__address">
            <ModAnimation className="cell small-24 large-20 xlarge-18">
              <p>
                {content.office._i18nCL.address.name && (
                  <>
                    {' '}
                    <strong>{content.office._i18nCL.address.name}</strong>
                    <br />{' '}
                  </>
                )}
                {content.office._i18nCL.address.street && (
                  <>
                    {' '}
                    {content.office._i18nCL.address.street} <br />{' '}
                  </>
                )}
                {content.office._i18nCL.address.city && (
                  <>
                    {' '}
                    {content.office._i18nCL.address.city} <br />{' '}
                  </>
                )}
                {content.office._i18nCL.address.country && (
                  <>
                    {' '}
                    {content.office._i18nCL.address.country} <br />{' '}
                  </>
                )}
                {content.office.phone && (
                  <>
                    {' '}
                    {'P: '}
                    <a href={'tel:' + content.office.phone}>{content.office.phone}</a>
                    <br />{' '}
                  </>
                )}
                {content.office.fax && (
                  <>
                    {' '}
                    {'F: ' + content.office.fax} <br />{' '}
                  </>
                )}
                {content.office.email && (
                  <>
                    <p
                      className="link link--mail"
                      onClick={() => {
                        this.sendMail(content.office.email)
                      }}
                    >
                      <ModSvg icon="mail" />
                      <FormattedMessage id="lawyer.send-email" defaultMessage="Send e-mail" />
                    </p>
                  </>
                )}
              </p>
            </ModAnimation>
          </div>
        )
      } else {
        return null
      }
    }

    return (
      <div className="office">
        <ModBlockLead content={content.blockLead} />

        {content.blocks && (
          <ModTemplateBlockList currentLanguage={currentLanguage} templateData={content.blocks} rootState={rootState} />
        )}

        <div className="grid align-center">
          <ModAnimation className="cell small-24 large-20 xlarge-18">
            <ModAnchorTag className="link link--internal" linkObject={content.lawyersLink}>
              <FormattedMessage id="office.all-lawyers" defaultMessage="All Lawyers" />{' '}
              {plainTitle && (
                <>
                  &nbsp;
                  {'in ' + plainTitle} <br />{' '}
                </>
              )}
            </ModAnchorTag>
          </ModAnimation>

          {content.office.googleUrl && (
            <ModAnimation className="cell small-24 large-20 xlarge-18">
              <br />
              <a className="link link--internal" href={content.office.googleUrl} target="_blank">
                <FormattedMessage id="office.directions" defaultMessage="Get Directions" />
              </a>
            </ModAnimation>
          )}
        </div>

        {getContactInfo()}
        {getPractiseAreas()}
        {getSectors()}
      </div>
    )
  }
}
