import * as React from 'react'
import { blockAccordion } from '../../interfaces/Interfaces'
import { injectIntl, FormattedMessage } from 'react-intl'
import ModAccordionItem from 'components/common/ModAccordionItem'
import ModAnimation from 'components/common/ModAnimation'
import ModBlockRichText from './ModBlockRichText'

export interface ModBlockAccordionProps {
  content: blockAccordion
}

function ModBlockAccordion(props: ModBlockAccordionProps) {
  const { content } = props
  const [active, setActive] = React.useState(0)
  const [lastActive, setLastActive] = React.useState(0)
  let items = []
  let type = 'faq'

  if (content.accordionType.faq) {
    items = content.accordionType.faq
    type = 'faq'
  } else if (content.accordionType.roadmap) {
    items = content.accordionType.roadmap
    type = 'roadmap'
  }

  function toggleElement(element) {
    if (element.style.maxHeight) {
      element.style.maxHeight = null
    } else {
      element.style.maxHeight = element.scrollHeight + 'px'
    }
  }

  React.useEffect(() => {
    if (active) {
      const currentContent = document.getElementById('accordion__content-' + content._id + '-' + active)
      toggleElement(currentContent)
      setLastActive(active)

      if (lastActive) {
        const lastContent = document.getElementById('accordion__content-' + content._id + '-' + lastActive)
        toggleElement(lastContent)
        setLastActive(active)
      }
    } else if (lastActive) {
      const lastContent = document.getElementById('accordion__content-' + content._id + '-' + lastActive)
      toggleElement(lastContent)
      setLastActive(active)
    }
  }, [active])

  const accordionItems = (items || []).map((item, index) => {
    if (type === 'faq') {
      if (item.richTextItem) {
        return <ModBlockRichText key={index} className={'block-accordion-rich-text'} content={item.richTextItem} />
      } else if (item.accordionItem) {
        return (
          <ModAccordionItem
            id={content._id}
            content={item.accordionItem}
            key={index}
            index={index + 1}
            active={active}
            setActive={setActive}
          ></ModAccordionItem>
        )
      }
    } else {
      return (
        <ModAccordionItem
          id={content._id}
          content={item}
          key={index}
          index={index + 1}
          active={active}
          setActive={setActive}
        ></ModAccordionItem>
      )
    }
  })

  return (
    <div className="block-accordion grid align-center">
      <div className="cell small-24 large-20 xlarge-18">
        <ModAnimation>
          <ul className={'accordion accordion--' + type}>{accordionItems}</ul>
        </ModAnimation>
      </div>
    </div>
  )
}
export default injectIntl(ModBlockAccordion)
