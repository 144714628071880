import * as React from "react";

import { Footer, SocialMedia } from "../interfaces/Interfaces";
import ModAnchorTag from "./common/ModAnchorTag";
import { NavigationNode, Menu } from "../interfaces/Interfaces";
import ModImgTag from "./common/ModImgTag";
import ModRichText from "./common/ModRichText";

export interface ModFooterProps {
  content: Footer;
  currentLanguage: string;
  socialMedia: SocialMedia;
}

export default class ModFooter extends React.Component<ModFooterProps, {}> {
  constructor(props) {
    super(props);
    this.createNavigationNode = this.createNavigationNode.bind(this);
  }

  createNavigationNode(menu: Menu) {
    //TODO: check if navigation active

    return menu.menuItems.reduce((prevItem, item, index) => {
      if (!item._i18nCL) {
        return prevItem;
      }
      prevItem.push(
        <li key={index}>
          <ModAnchorTag linkObject={item.link}>
            {item._i18nCL.label}
          </ModAnchorTag>
        </li>
      );
      return prevItem;
    }, []);
  }

  render() {
    const { content, socialMedia } = this.props;

    if (!content) {
      return null;
    }

    let mainNavigation = this.createNavigationNode(content.menu);
    let footerNavigation = this.createNavigationNode(content.metamenu);

    const socialMediaContent = socialMedia.socialMedia.reduce(
      (prevItem, item, index) => {
        if (item.hasOwnProperty("image")) {
          prevItem.push(
            <ModAnchorTag key={index} linkObject={item.link}>
              <ModImgTag
                width={40}
                height={40}
                imgObject={item.image}
                fileFormat={"jpg"}
              />
            </ModAnchorTag>
          );
        }
        return prevItem;
      },
      []
    );

    // let getLocationColumn = content.offices.map((office, index) => {
    //   if (office._i18nCL){
    //     return(
    //       <div className={"footer__locations__location cell small-12 large-5 " + (index == 0 ? "large-offset-2" : "")} key={office.internalDescription} >
    //         {office._i18nCL.title && (<> <h4>{office._i18nCL.title}</h4></>)}
    //           <p>
    //           {office._i18nCL.address.name && (<> <strong>{office._i18nCL.address.name}</strong><br /> </>)}
    //             {office._i18nCL.address.street && (<> {office._i18nCL.address.street} <br /> </>)}
    //             {office._i18nCL.address.city && (<> {office._i18nCL.address.city} <br /> </>)}
    //             {office._i18nCL.address.country && (<> {office._i18nCL.address.country} <br /> </>)}
    //             {office.phone && (<> {office.phone} <br /> </>)}
    //             {office.fax && (<> {office.fax} <br /> </>)}
    //             {office.email && (<> <a href={"mailto:" + office.email}> {office.email} </a> </>)}
    //           </p>
    //       </div>
    //     )
    //   }
    //   else{
    //     return null
    //   }
    // })

    return (
      <footer className="footer" id="footer">
        <div className="grid footer__grid">
          <div className="cell small-20 large-18 footer__menu">
            <ul className="menu">{mainNavigation}</ul>
            <ul className="menu menu--footer">{footerNavigation}</ul>
          </div>
          <div className="cell small-4 large-5 footer__social">
            {socialMediaContent}
          </div>
        </div>
      </footer>
    );
  }
}
