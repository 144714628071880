import * as React from 'react'
import { Media } from '../../interfaces/Interfaces'
import ModImgTag from './ModImgTag'

export interface ModVideoTagProps {
  width?: number
  height?: number
  className?: string
  videoObject: Media
  muted?: boolean
  loop?: boolean
  autoPlay?: boolean
  visible?: boolean
}
interface ModVideoTagState {
  visible: boolean
}

export default class ModVideoTag extends React.Component<ModVideoTagProps, ModVideoTagState> {
  lazyComponent: any
  observer: IntersectionObserver

  constructor(props) {
    super(props)

    this.lazyComponent = null
    this.state = { visible: props.visible ?? false }

    this.startObserve = this.startObserve.bind(this)
    this.stopObserve = this.stopObserve.bind(this)
    this.callBack = this.callBack.bind(this)

    if (typeof window !== 'undefined' && typeof IntersectionObserver !== 'undefined') {
      this.observer = new IntersectionObserver(this.callBack, {
        rootMargin: '300px',
        threshold: 0.1,
      })
    }
  }

  componentDidMount() {
    this.startObserve()
  }

  componentDidUpdate() {
    this.startObserve()
  }

  componentWillUnmount() {
    this.stopObserve()
  }

  startObserve() {
    this.stopObserve()
    if (this.observer && this.lazyComponent) {
      this.observer.observe(this.lazyComponent)
    }
  }

  stopObserve() {
    if (this.observer && this.lazyComponent) {
      this.observer.unobserve(this.lazyComponent)
    }
  }

  callBack(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.setState({ visible: true })
      }
    })
  }

  render() {
    const { videoObject, className, muted, loop, autoPlay } = this.props

    if (!videoObject) {
      console.error('ModVideoTag: no image object provided')
      return (
        <figure>
          <img src={require('static/img/broken-image.png')} />
        </figure>
      )
    }

    if (typeof videoObject === 'string') {
      console.error('ModVideoTag: video id instead of image object provided', videoObject)
      return (
        <figure>
          <img src={require('static/img/broken-image.png')} />
        </figure>
      )
    }

    let cloudinaryParams = ['q_100']
    let addTransformation = false

    if (this.props.width) {
      addTransformation = true
      cloudinaryParams.push('w_' + this.props.width)
    }
    if (this.props.height) {
      addTransformation = true
      cloudinaryParams.push('h_' + this.props.height)
    }

    if (addTransformation) {
      cloudinaryParams.push('c_fill')
    }

    // use default media
    let url = ModImgTag.addCloudinaryParamsToUrl(videoObject.media.url, [])
    let altText = ''
    let title = ''
    if (videoObject._i18nCL) {
      altText = videoObject._i18nCL.altText
      title = videoObject._i18nCL.title
      if (videoObject._i18nCL.media) {
        // use i18 media if present
        url = ModImgTag.addCloudinaryParamsToUrl(videoObject._i18nCL.media.url, cloudinaryParams)
      }
    }

    if (!this.state.visible) {
      return (
        <figure className={className}>
          <img
            title={title}
            alt=""
            ref={(input) => {
              this.lazyComponent = input
            }}
          />
        </figure>
      )
    }

    return (
      <figure className={className}>
        <video
          width={this.props.width}
          height={this.props.height}
          muted={muted}
          loop={loop}
          playsInline
          autoPlay={autoPlay}
          title={title}
        >
          <source type="video/mp4" src={url} />
        </video>
      </figure>
    )
  }
}
