import * as React from 'react'
import { injectIntl } from 'react-intl'
import { FilterResult, FilterType, Filter, Lawyer, NewsPublication, BlockNewsPublicationsGridType } from 'interfaces/Interfaces'
import * as isoFetch from 'isomorphic-fetch'
import ModFilterElement from '../common/ModFilterElement'
import ModFilterSearchInput from '../common/ModFilterSearchInput'
import ModNewsPublicationsTeaser from '../common/ModNewsPublicationsTeaser'
import ModLawyersTeaser from '../common/ModLawyersTeaser'
import ModSvg from '../common/ModSvg'
import ModAnimation from '../common/ModAnimation'
import { filterQueryParams } from 'vo/FilterQueryParams'
import { NavigationNodeContext } from 'components/ModApp'
import { TweenMax, TimelineMax, Power0 } from 'gsap'
import InView, { useInView } from 'react-intersection-observer'
import { FormattedMessage } from 'react-intl'
import { geti18nClIfMissing } from 'components/common/Utils'
import ModLawyersGrid from 'components/common/ModLawyersGrid'

export interface ModBlockFilterProps {
  type: FilterType
  result: FilterResult
  filters: Filter[]
  currentLanguage: string
  intl: any
  displaySize: string
  pagingLength: number
}

function ModBlockFilter(props: ModBlockFilterProps) {
  const { location, router } = React.useContext(NavigationNodeContext)

  const { type, filters, currentLanguage, displaySize } = props
  const initialRef = React.useRef(true)
  const filterWrapperRef = React.useRef<HTMLDivElement>()
  const pagingLength = React.useRef(props.pagingLength)
  const toggleFilterTimeline = React.useRef(null)

  const [searchInput, setSearchInput] = React.useState(() => {
    if (location.query) {
      return location.query.get(filterQueryParams.term) || ''
    }
    return ''
  })
  const [activeFilters, setActiveFilters] = React.useState(() => {
    let initialActiveFilters = {}
    if (location.query) {
      location.query.forEach((value, key) => {
        const filter = props.filters.find((filter: Filter) => {
          return filter.queryKey == key
        })
        if (filter) {
          initialActiveFilters[key] = value
        }
      })
    }
    return initialActiveFilters
  })
  const [offset, setOffset] = React.useState(props.result.offset)
  const [result, setResult] = React.useState(props.result)
  const [isFilterScrolled, setIsFilterScrolled] = React.useState(false)

  const [searchInputToggle, setSearchInputToggle] = React.useState(false)
  const [filterInputOpenKey, setFilterInputOpenKey] = React.useState(null)

  const [filterToggleActive, setFilterToggleActive] = React.useState(true)

  const [isLoading, setIsLoading] = React.useState(false)
  const [isError, setIsError] = React.useState(false)

  React.useEffect(() => {
    fetchResults()
  }, [activeFilters, offset, searchInput])

  React.useEffect(() => {
    if (toggleFilterTimeline.current) {
      TweenMax.set('#filter', { clearProps: 'all' })
      toggleFilterTimeline.current.kill()
    }
    let tftl = new TimelineMax({ paused: true })
    tftl.add(
      TweenMax.from('#filter', 0.5, {
        height: 0,
        overflow: 'hidden',
        ease: Power0.easeOut,
      })
    )
    tftl.progress(1).pause()
    toggleFilterTimeline.current = tftl

    if (displaySize == 'mobile' && !toggleFilterTimeline.current.reversed()) {
      toggleFilterTimeline.current.reverse()
      setFilterToggleActive(false)
    } else if (displaySize == 'desktop' && toggleFilterTimeline.current.reversed()) {
      toggleFilterTimeline.current.play()
      setFilterToggleActive(true)
    }
  }, [displaySize])

  React.useEffect(() => {
    if (toggleFilterTimeline.current.reversed() && filterToggleActive) {
      toggleFilterTimeline.current.play()
    } else if (!toggleFilterTimeline.current.reversed() && !filterToggleActive) {
      toggleFilterTimeline.current.reverse()
      setSearchInputToggle(false)
    }
  }, [filterToggleActive])

  function handleClickOutside(event) {
    if (!event.target.closest('.filter-wrapper')) {
      onFilterToggle(filterInputOpenKey, false)
    }
  }

  function handleScroll() {
    const { offsetTop, offsetHeight } = filterWrapperRef.current

    if (document.documentElement.scrollTop > offsetTop + offsetHeight) {
      setIsFilterScrolled(true)
    } else {
      setIsFilterScrolled(false)
    }
  }

  function scrollToElement(el) {
    el.preventDefault()
    scroll({
      top: filterWrapperRef.current.offsetTop - 100,
      behavior: 'smooth',
    })
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('scroll', handleScroll)
    }
  })

  const ref = React.useRef()
  const [dimensions, setDimensions] = React.useState({ width: 220, height: 220 })

  React.useLayoutEffect(() => {
    function handleResize() {
      if (ref.current) {
        setDimensions({
          // @ts-ignore
          width: ref.current.offsetWidth,
          // @ts-ignore
          height: ref.current.offsetHeight,
        })
      }
    }

    window.addEventListener('resize', handleResize)

    handleResize()
  }, [])

  function fetchResults() {
    //don't fetch on initial render
    if (initialRef.current) {
      initialRef.current = false
      return
    }

    let queryParams = new URLSearchParams()
    if (searchInput && searchInput != '') {
      queryParams.append(filterQueryParams.term, searchInput)
    }

    Object.keys(activeFilters).forEach(function (key, index) {
      const activeValue = activeFilters[key]
      if (activeValue && activeValue != '') {
        queryParams.append(key, activeValue)
      }
    })

    if (offset > 0) {
      queryParams.append(filterQueryParams.limit, (pagingLength.current + offset).toString())
    }

    //add queryparams to url
    router.replaceSearchParams(queryParams)

    queryParams.append(filterQueryParams.type, type)
    queryParams.append(filterQueryParams.lang, currentLanguage)
    queryParams.set(filterQueryParams.limit, pagingLength.current.toString())
    queryParams.append(filterQueryParams.offset, offset.toString())

    setIsError(false)
    setIsLoading(true)
    isoFetch('/api/filter?' + queryParams.toString())
      .then(function (response) {
        return response.json()
      })
      .then((filterResult: FilterResult) => {
        if (offset > 0) {
          filterResult.entries = result.entries.concat(filterResult.entries)
        }
        setResult(filterResult)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        setIsError(true)
      })
  }

  function onSearchValueChange(value) {
    setSearchInput(value)
    setOffset(0)
  }

  function onSearchValueDelete(event) {
    setSearchInput('')
    setOffset(0)
  }

  function onSearchToggleChange(event) {
    setSearchInputToggle(!searchInputToggle)
    setFilterInputOpenKey(null)
  }

  function onFilterChange(key: string, value?: string, text?: string) {
    const filterObjects = {}
    filterObjects[key] = value

    setActiveFilters(Object.assign({}, activeFilters, filterObjects))
    setOffset(0)
  }

  function onFilterToggle(key: string, open: boolean) {
    if (open) {
      setFilterInputOpenKey(key)
      setSearchInputToggle(false)
    } else {
      setFilterInputOpenKey(null)
      setSearchInputToggle(false)
    }
  }

  function getFilterElement(index, titleIntlId, options, queryKey) {
    const { formatMessage } = props.intl
    const title = formatMessage({ id: titleIntlId })

    if (options.length == 0) return null

    return (
      <ModFilterElement
        key={index}
        title={title}
        options={options}
        queryKey={queryKey}
        onFilterChange={onFilterChange}
        onFilterToggle={onFilterToggle}
        filterOpen={filterInputOpenKey == queryKey}
        activeValue={activeFilters[queryKey] ? activeFilters[queryKey] : null}
      />
    )
  }

  function getFilterElements(filters: Filter[]) {
    const filterElements = filters.map((filter, index) => {
      return getFilterElement(index, filter.titleIntlId, filter.options, filter.queryKey)
    })

    return (
      <ModAnimation className={'filter-wrapper'} id="filter-wrapper">
        <div
          className="filter-toggle"
          onClick={(e) => {
            setFilterToggleActive(!filterToggleActive)
          }}
        >
          <span>
            <ModSvg icon="filter" />
            Filter
          </span>
        </div>
        <div className={'filter' + (isFilterActive() ? ' is-active' : '')} id="filter">
          {/* <ModFilterSearch
            searchValue={searchInput}
            onSearchToggle={onSearchToggleChange}
            onSearchDelete={onSearchValueDelete}
            isToggled={searchInputToggle}
          /> */}
          <ModFilterSearchInput
            onSearchString={onSearchValueChange}
            onSearchDelete={onSearchValueDelete}
            searchValue={searchInput}
          />
          {filterElements}
        </div>
      </ModAnimation>
    )
  }

  function isFilterActive() {
    let isActive = false

    const filterKeys = Object.keys(activeFilters)
    filterKeys.forEach((key) => {
      if (activeFilters[key] != null) {
        isActive = true
      }
    })

    return isActive || searchInput
  }

  let filterResultsNews = result.entries.map((item, index) => {
    let newsPublicationItem = item as NewsPublication

    item = geti18nClIfMissing(item)

    if (item._i18nCL) {
      return (
        <div
          style={{ minHeight: dimensions.height }}
          className="cell small-24 medium-12 large-12 xlarge-8"
          key={item._id}
          ref={ref}
        >
          <ModNewsPublicationsTeaser
            currentLanguage={currentLanguage}
            content={newsPublicationItem}
            type={BlockNewsPublicationsGridType.news}
          />
        </div>
      )
    }
  })

  let filterResultPublications = result.entries.map((item, index) => {
    let newsPublicationItem = item as NewsPublication

    item = geti18nClIfMissing(item)

    if (item._i18nCL) {
      return (
        <div
          style={{ minHeight: dimensions.height }}
          className="cell small-24 medium-12 large-12 xlarge-8"
          key={item._id}
          ref={ref}
        >
          <ModNewsPublicationsTeaser
            currentLanguage={currentLanguage}
            content={newsPublicationItem}
            type={BlockNewsPublicationsGridType.publications}
          />
        </div>
      )
    }
  })

  let filterResultsLawyers = result.entries.map((item, index) => {
    return {
      content: item as Lawyer,
      ref: ref,
      minHeight: dimensions.width,
    }
  })

  let filterResults = (type) => {
    if (result.length > 0) {
      switch (type) {
        case 'lawyer':
          return <ModLawyersGrid content={filterResultsLawyers} />
        case 'news':
          return <div className="grid news-publications">{filterResultsNews}</div>
        case 'publication':
          return <div className="grid news-publications">{filterResultPublications}</div>
      }
    } else {
      return (
        <h3 className={'label'}>
          <FormattedMessage id="global.no-results" defaultMessage="No Results found" />
        </h3>
      )
    }
  }

  let showMore = () => {
    if (offset + result.length < result.total) {
      return (
        <InView
          onChange={(inView, entry) => {
            if (inView) {
              setOffset(result.offset + result.length)
            }
          }}
        >
          {({ inView, ref, entry }) => <div ref={ref}>{/* SCROLL INDICATOR */}</div>}
        </InView>
      )
    } else {
      return null
    }
  }

  return (
    <div className="block-filter">
      <div className="grid align-center" ref={filterWrapperRef}>
        <div className="cell small-24 large-22 xlarge-20">{getFilterElements(filters)}</div>
      </div>

      {/* <a href="#filter-wrapper" onClick={scrollToElement(this)}> */}
      <a
        href="#filter-wrapper"
        onClick={(el) => {
          scrollToElement(el)
        }}
        className={'filter-scroll ' + (isFilterScrolled ? 'filter-scroll--active' : '')}
      >
        <ModSvg icon="filter" />
      </a>

      <div className="grid">
        <div className="cell small-24">{filterResults(type)}</div>
      </div>
      {showMore()}
    </div>
  )
}

export default injectIntl(ModBlockFilter)
