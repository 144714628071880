import moment = require('moment')
import * as React from 'react'
import {
  News,
  Publication,
  BlockNewsPublicationsGridType,
  NewsPublication,
  NewsCategory,
  PublicationCategory,
  PageType,
} from 'interfaces/Interfaces'
import ModRichText from './ModRichText'
import ModAnchorTag from './ModAnchorTag'
import ModAnimation from './ModAnimation'
import { createStaticPageLinkObject } from 'common/CmsUtils'
import ModImgTag from './ModImgTag'
import { getPublicationCategoryTranslationKey, getNewsCategoryTranslationKey } from './Utils'
import { FormattedMessage } from 'react-intl'
import { richTextHasContent, richTextToPlainText } from 'common/Utils'

export interface ModNewsPublicationsTeaserProps {
  currentLanguage: string
  content: NewsPublication
  type: string
}

export default class ModNewsPublicationsTeaser extends React.Component<ModNewsPublicationsTeaserProps, {}> {
  render() {
    const { content, type, currentLanguage } = this.props

    if (!(content._i18nCL && richTextHasContent(content._i18nCL.title))) {
      return null
    }

    const i18n = content._i18nCL

    let category = null
    let linkObject = null
    let authors = null

    const dateFormat = new Date(content.date)
    const date = currentLanguage === 'de' ? moment(dateFormat).format('DD. MMMM YYYY') : moment(dateFormat).format('DD MMMM YYYY')

    let getImage = null
    let logo = null

    switch (type) {
      case BlockNewsPublicationsGridType.publications:
        const publication = content as Publication
        category = <FormattedMessage id={getPublicationCategoryTranslationKey(publication.category)} />
        linkObject = createStaticPageLinkObject(publication, PageType.publication)

        authors = content.mainAuthors.map((author, index) => {
          const key = Object.keys(author)[0] as keyof typeof author

          switch (key) {
            case 'internal': {
              const value = author[key]

              if (typeof value === 'string') return

              return (
                <ModAnchorTag key={index} linkObject={createStaticPageLinkObject(value, PageType.lawyer)}>
                  {value.contact.academicTitle} {value.contact.firstName} {value.contact.lastName}
                </ModAnchorTag>
              )
            }

            case 'external': {
              const value = author[key]
              return <span key={index}>{value.name}</span>
            }
          }
        })

        if (publication.guide && publication.guide.logo) {
          logo = (
            <ModImgTag className={'news-publications__teaser__footer__logo'} imgObject={publication.guide.logo} width={480} />
          )
        }
        break

      case BlockNewsPublicationsGridType.news:
        const news = content as News
        category = <FormattedMessage id={getNewsCategoryTranslationKey(news.category)} />
        linkObject = createStaticPageLinkObject(news, PageType.news)

        if (news.image) {
          getImage = (
            <ModImgTag className={'news-publications__teaser__image'} imgObject={content.image} width={960} height={600} />
          )
        } else {
          getImage = <div className="news-publications__teaser__ratio"></div>
        }
        break
    }

    const plainTitle = richTextToPlainText(i18n.title)

    const title = (title) => (plainTitle.length > 75 ? plainTitle.match(/.{1,75}(\s|$)/g)[0] + '...' : plainTitle)
    const text = (text) =>
      i18n.teaserText.length > 150
        ? i18n.teaserText.replace(/(\r\n|\n|\r)/gm, ' ').match(/.{1,150}(\s|$)/g)[0] + '...'
        : i18n.teaserText

    return (
      <ModAnimation
        tag="article"
        className={
          `news-publications__teaser news-publications__teaser--${content.category}` +
          (type === BlockNewsPublicationsGridType.news ? ' news-publications__teaser--image' : '')
        }
      >
        <div>
          <ModAnchorTag linkObject={linkObject}>
            <header className={'news-publications__teaser__header'}>
              {getImage}

              <div className={'news-publications__teaser__header-content'}>
                <span className="label news-publications__teaser__label">
                  <span>{category}</span> - <time>{date}</time>
                </span>
                <h2 className={'news-publications__teaser__title'}>{title(i18n.title)}</h2>
              </div>
            </header>
          </ModAnchorTag>

          {authors && <div className={'news-publications__teaser__authors'}>{authors}</div>}

          <div className={'news-publications__teaser__content'}>
            <p>{text(i18n.teaserText)}</p>
            {/* <ModRichText richText={i18n.leadText} /> */}
          </div>
        </div>

        <div className="news-publications__teaser__footer">
          <div className="news-publications__teaser__footer__link">
            <ModAnchorTag linkObject={linkObject} linkText={true}>
              <FormattedMessage id="global.read-more" defaultMessage="Read more" />
            </ModAnchorTag>
          </div>
          {logo}
        </div>
      </ModAnimation>
    )
  }
}
