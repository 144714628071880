import * as React from 'react'
import { Expertise, FilterOption } from 'interfaces/Interfaces'
import { injectIntl } from 'react-intl'
import ModSvg from '../common/ModSvg'

export interface ModFilterElementProps {
  options: FilterOption[]
  activeValue?: string
  title: string
  queryKey: string
  filterOpen: boolean
  onFilterChange: (key: string, value: string) => void
  onFilterToggle: (key: string, open: boolean) => void
  intl: any
}

class ModFilterElement extends React.Component<ModFilterElementProps> {
  constructor(props) {
    super(props)

    this.state = {
      filterOpen: false,
    }

    this.changeOption = this.changeOption.bind(this)
    this.toggleFilter = this.toggleFilter.bind(this)
  }

  changeOption(value) {
    this.props.onFilterChange(this.props.queryKey, value || null)
  }

  toggleFilter(el) {
    this.props.onFilterToggle(this.props.queryKey, !this.props.filterOpen)
  }

  render() {
    const { options, activeValue, title, filterOpen } = this.props
    const { formatMessage } = this.props.intl

    let getOptionText = (option: FilterOption) => {
      return option.hasOwnProperty('textIntlId') ? formatMessage({ id: option.textIntlId }) : option.text
    }

    let filterElementOptions = options.map((item, index) => {
      return (
        <option key={index} value={item.value}>
          {getOptionText(item)}
        </option>
      )
    })

    let filterElementDropdownOptions = options.map((item, index) => {
      return (
        <li
          className={'filter__element__dropdown__options__option ' + (item.value == valueSelected ? 'active' : '')}
          role="option"
          key={index}
          id={item.value}
          onClick={() => this.changeOption(item.value)}
        >
          {getOptionText(item)}
        </li>
      )
    })

    let getFilterIcon = () => {
      if (activeValue) {
        return (
          <span onClick={() => this.changeOption('')}>
            <ModSvg icon="bold-close" />
          </span>
        )
      } else {
        return (
          <span onClick={this.toggleFilter}>
            <ModSvg icon="arrow-down" />
          </span>
        )
      }
    }

    const allTitle = formatMessage({ id: 'filter.all' }) + ' ' + title
    let valueSelected = ''
    let titleSelected = allTitle
    let activeOption = options.find((option) => {
      return option.value == activeValue
    })

    if (activeOption) {
      valueSelected = activeOption.value
      titleSelected = getOptionText(activeOption)
    }

    return (
      <div className={'filter__element' + (activeValue || filterOpen ? ' is-active' : '')}>
        <select
          className="filter__element__select"
          value={valueSelected}
          onChange={(val) => {
            this.changeOption(val.target.value)
          }}
        >
          <option value={''}>{allTitle}</option>
          {filterElementOptions}
        </select>
        <div className="filter__element__dropdown" onClick={this.toggleFilter}>
          <span className={'filter__element__dropdown__selected'}>{titleSelected}</span>
          <ul className={'filter__element__dropdown__options ' + (filterOpen ? 'open' : 'closed')}>
            {filterElementDropdownOptions}
          </ul>
        </div>
        <div className="filter__element__icon">{getFilterIcon()}</div>
      </div>
    )
  }
}

export default injectIntl(ModFilterElement)
